@import '@yulintu/freesia-design/css/variables.less';

.freedesign.list-grid-v4 {

    overflow-y: auto;
    overflow-x: hidden;

    position: relative;

    >.list-grid-v4-item {
        height          : auto;
        width           : auto;
        margin          : 0 10px 10px 0;
        padding         : 0;
        border          : 0;
        background-color: transparent;
        box-shadow      : unset;
        text-align      : left;

        .list-grid-v4-item--list-item {
            width : 100%;
            height: 100%;

            padding: 10px 20px;
        }

        .list-grid-v4-item--gallery-item {
            width : auto;
            height: auto;

            border-radius: 4px;
            overflow     : hidden;

            padding: 0;
        }
    }

    &.list-grid-v4-v {
        display       : flex;
        flex-direction: column;


        >.list-grid-v4-item {
            width: 100%;
        }

    }

    &.list-grid-v4-h {
        display       : flex;
        flex-direction: row;
        flex-wrap     : wrap;

        >.list-grid-v4-item {
            margin: 10px 10px 10px 10px;
        }
    }
}


.freedesign.list-grid-v4 {
    &.grafana {

        .panel {
            border          : 1px solid transparent;
            border-radius   : 4px;
            background-color: rgb(244, 245, 245);
        }

        .panel.hoverable:hover {
            border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            background-color: white;
        }

        .panel.hoverable:active {
            border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            background-color:~'var(--@{ant-prefix}-primary-2)';
        }
    }

    &.grafana.white {

        .panel {
            background-color: white;
        }

        .panel.hoverable:hover {
            border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            background-color:~'var(--@{ant-prefix}-primary-1)';
        }

        .panel.hoverable:active {
            border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            background-color:~'var(--@{ant-prefix}-primary-2)';
        }
    }

    &.grafana.white.alpha50 {

        .panel {
            background-color: rgba(255, 255, 255, .5);
        }

        .panel.hoverable:hover {
            border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            background-color:~'var(--@{ant-prefix}-primary-1)';
        }

        .panel.hoverable:active {
            border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            background-color:~'var(--@{ant-prefix}-primary-2)';
        }
    }

    &.grafana.white.alpha30 {

        .panel {
            background-color: rgba(255, 255, 255, .3);
        }

        .panel.hoverable:hover {
            border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            background-color:~'var(--@{ant-prefix}-primary-1)';
        }

        .panel.hoverable:active {
            border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            background-color:~'var(--@{ant-prefix}-primary-2)';
        }
    }

    &.liststyle {

        .panel {
            border-top      : 1px solid transparent;
            border-right    : 1px solid transparent;
            border-left     : 1px solid transparent;
            border-bottom   : 1px solid rgba(36, 41, 46, .12);
            border-radius   : 4px;
            background-color: transparent;
        }

        button:last-child {
            .panel {
                border-bottom: 1px solid transparent;
            }
        }

        .panel.hoverable:hover {
            border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            background-color: white;
        }

        .panel.hoverable:active {
            border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            background-color:~'var(--@{ant-prefix}-primary-1)';
        }

        .item-selected {
            .panel.hoverable:hover {
                background-color:~'var(--@{ant-prefix}-primary-1)';
                border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            }

            .panel.hoverable:active {
                background-color:~'var(--@{ant-prefix}-primary-1)';
                border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            }

            .panel.hoverable {
                background-color:~'var(--@{ant-prefix}-primary-1)';
                border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
            }
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;