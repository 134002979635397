@import '@yulintu/freesia-design/css/variables.less';

.step-wizzard {
    height        : 100%;
    display       : flex;
    flex-direction: column;

    >.steps {
        margin: 5px 30px 15px 30px;

        .ant4-steps-item-finish{

            
        .ant4-steps-item-icon{
            border-color: ~'var(--@{ant-prefix}-success-color)';
            background-color: ~'var(--@{ant-prefix}-success-color)';

            .ant4-steps-icon {
                color: white;
            }
        }}

        .ant4-steps-item-icon{
            

        .ant4-steps-icon {
            font-size: 20px;

            .anticon-check {
                margin-top: 5px;
            }
        }
    }
    }

    >.tabs {
        flex      : 1 1;
        height    : 0;
        overflow-x: hidden;

        >.gradient-ink-bar {

            >.ant4-tabs-nav {
                display: none;
            }
        }
    }


}
@ant-prefix: ant4;@root-entry-name: variable;