@import '@yulintu/freesia-design/css/variables.less';

.layout-classic {
    display       : flex;
    height        : 100%;
    flex-direction: column !important;

    .ant4-layout-header.layout-classic-header {
        height        : 60px;
        padding       : 0;
        line-height   : 60px;
        // box-shadow : 0 1px 4px #00000050;
        z-index       : 1;

        >.header-content {
            height     : 100%;
            width      : 100%;
            display    : flex;
            align-items: center;

            button.app-gallery {
                flex-shrink: 0;

                .anticon {
                    color: white;
                }

                width          : 60px;
                height         : 100%;
                justify-content: center;
            }

            .app-title {
                padding       : 4px 15px;
                flex-shrink   : 0;
                height        : 100%;
                // padding    : 0 12px;
                // font-size  : 24px;
                // margin-top : -8px;
                // margin-left: 20px;

                .logo {
                    max-height: 34px;
                    min-width : 34px;
                    min-height: 34px;
                }

                .anticon {
                    font-size: 34px;
                    color    : white;
                }

                .title {
                    font-size    : 22px;
                    color        : white;
                    overflow     : hidden;
                    text-overflow: ellipsis;
                    white-space  : nowrap;

                    align-items: center;

                    .subtitle {
                        opacity    : .8;
                        font-weight: 500;
                        margin-left: 10px;
                        font-size  : 16px;

                        .subtitle-extra {
                            margin-left: 5px;

                            .anticon {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .addons {
                padding-right: 0px;
            }
        }

        .primary-header-dropdown {
            color  : rgba(255, 255, 255, 0.65);
            padding: 0 15px;

            &:hover {
                color: white;
            }

            &.ant4-dropdown-open {
                color: white;
            }
        }

        .primary-header-button {
            height        : 100%;
            border-radius : 0;
            display       : flex;
            flex-direction: row;
            align-items   : center;
            border-width  : 0;

        }

        .drawer-swticher {
            width: 50px;
        }

        &.dark {
            background: #3d4146;
        }

        &.light {
            background   : white;
            // box-shadow: 0 2px 8px #f0f1f2;

            >.header-content {

                button.app-gallery {
                    .anticon {
                        color: inherit;
                    }
                }

                .app-title {

                    .anticon {
                        color: inherit;
                    }

                    .title {
                        color: inherit;

                    }
                }

                .primary-header-dropdown {
                    color: #000000d9;


                }

                .header-menu.light {

                    .ant4-menu-item {
                        height             : 60px;
                        border-bottom-width: 3px;

                        &::after {
                            border-bottom-width: 3px;
                            right              : 0;
                            left               : 0;
                        }
                    }

                    .ant4-menu-item:hover {
                        border-bottom-width: 3px;

                        &::after {
                            border-bottom-width: 3px;
                            right              : 0;
                            left               : 0;
                        }
                    }

                    .ant4-menu-item-selected {
                        border-bottom-width: 3px;

                        &::after {
                            border-bottom-width: 3px;
                            right              : 0;
                            left               : 0;
                        }
                    }

                    .ant4-menu-submenu-selected {
                        border-bottom-width: 3px;

                        &::after {
                            border-bottom-width: 3px;
                            right              : 0;
                            left               : 0;
                        }
                    }

                    .ant4-menu-submenu-horizontal {
                        height             : 60px;
                        border-bottom-width: 3px;

                        &::after {
                            border-bottom-width: 3px;
                            right              : 0;
                            left               : 0;
                        }
                    }

                    .ant4-menu-submenu-horizontal:hover {
                        border-bottom-width: 3px;

                        &::after {
                            border-bottom-width: 3px;
                            right              : 0;
                            left               : 0;
                        }
                    }

                    .ant4-menu-submenu-active {
                        border-bottom-width: 3px;

                        &::after {
                            border-bottom-width: 3px;
                            right              : 0;
                            left               : 0;
                        }
                    }
                }

            }
        }

        &.shadow {
            z-index   : 2;
            box-shadow: 0 2px 4px #00000025;
        }

        &.shadow.light {
            z-index   : 2;
            box-shadow: 0 2px 8px #0000000e;
        }
    }

    .layout-classic-content-frame {
        position: relative;
        width   : 100% !important;

        &.shadow {
            box-shadow: 0 -2px 4px #00000025;
            z-index   : 2;
        }
    }

    .layout-classic-content {
        position: relative;
        height  : 100%;

        .layout-classic-content-content {
            // z-index: 1;
        }

        .sider-drawer-switcher {
            position: absolute;
            left    : 0;
            top     : 10px;
            z-index : 5;

            box-shadow   : 2px 0 8px #00000026;
            border-radius: 0 4px 4px 0;
            border       : 0;
            width        : 41px;
            height       : 40px;
            padding      : 0;
        }


        .layout-classic-content-sider {
            padding-top   : 7px;
            display       : flex;
            flex-direction: column;
            box-shadow    : 2px 0 8px 0 #1d23290d;
            z-index       : 1;
            transition    : width 0.2s, flex 0.2s, max-width 0.2s, min-width 0.2s;

            .ant4-layout-sider-trigger {
                background: transparent;
            }

            .sider-title {
                font-size       : 22px;
                padding         : 15px 20px 10px 30px;
                margin-bottom   : 5px;
                white-space     : nowrap;
                -o-text-overflow: ellipsis;
                text-overflow   : ellipsis;
                overflow        : hidden;
                cursor          : pointer;
            }

            &.ant4-layout-sider-collapsed {

                .sider-title-frame {
                    display        : flex;
                    flex-direction : row;
                    align-items    : center;
                    justify-content: center;
                }

                .sider-title {
                    visibility        : hidden;
                    padding           : 15px 0px 10px 0px;
                    width             : 22px;
                    // display        : flex;
                    // flex-direction : row;
                    // align-items    : center;
                    // justify-content: center;

                    &:first-letter {
                        visibility: visible !important;
                    }
                }
            }


            .anticon {
                transition: font-size 0.3s;
            }

            .ant4-layout-sider-children {
                flex      : 1 1;
                overflow-y: auto;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    width: 0;
                }

                &:hover::-webkit-scrollbar {
                    width: 12px;
                }

                // &::-webkit-scrollbar-thumb {
                //     background-color: transparent;
                //     transition      : background-color .3s;
                // }

                // &:hover::-webkit-scrollbar-thumb {
                //     background-color: rgba(128, 128, 128, .5);
                // }
            }

            .ant4-menu.ant4-menu-inline {
                // width             : calc(100% + 1px);
                border-right-color: transparent;

                .ant4-menu-sub {
                    .ant4-menu-item {
                        >.ant4-menu-title-content {
                            margin-left: 12px;
                        }
                    }

                    .ant4-menu-submenu {
                        >.ant4-menu-submenu-title {
                            >.ant4-menu-title-content {
                                margin-left: 12px;
                            }
                        }
                    }
                }

                .ant4-menu-item {
                    margin-bottom : 2px;
                    margin-top    : 2px;
                    line-height   : 44px;
                    height        : 44px;
                    display       : flex;
                    flex-direction: row;
                    align-items   : center;

                    .anticon {
                        font-size: 26px;
                    }
                }

                .ant4-menu-submenu-title {
                    margin-bottom : 2px;
                    margin-top    : 2px;
                    line-height   : 44px;
                    height        : 44px;
                    display       : flex;
                    flex-direction: row;
                    align-items   : center;

                    .anticon {
                        font-size: 26px;
                    }
                }

                .ant4-menu-item-group {
                    margin-bottom: 10px;

                    .ant4-menu-item-group-title {
                        padding-left: 24px;
                    }
                }


                .ant4-menu-sub.ant4-menu-inline {
                    background: transparent;
                }
            }

            .ant4-menu-inline-collapsed {
                width             : calc(100% + 1px);
                border-right-color: transparent;

                .ant4-menu-item {
                    margin-bottom  : 2px;
                    margin-top     : 2px;
                    line-height    : 44px;
                    height         : 44px;
                    display        : flex;
                    flex-direction : row;
                    align-items    : center;
                    justify-content: center;
                    padding        : 0 !important;
                    padding-left   : 0 !important;

                    .ant4-menu-item-icon {
                        margin-top: 4px !important;
                        font-size : 28px !important;
                    }

                    .ant4-menu-title-content {
                        // display: none !important;
                        width      : 0;
                        margin-left: 0;
                    }
                }

                .ant4-menu-item-group {
                    margin-bottom: 10px;

                    .ant4-menu-item-group-title {
                        padding-left   : 0;
                        padding-right  : 0;
                        display        : flex;
                        flex-direction : row;
                        align-items    : center;
                        justify-content: center;
                    }
                }

                .ant4-menu-submenu-title {
                    margin-bottom  : 2px;
                    margin-top     : 2px;
                    line-height    : 44px;
                    height         : 44px;
                    display        : flex;
                    flex-direction : row;
                    align-items    : center;
                    justify-content: center;
                    padding        : 0 !important;
                    padding-left   : 0 !important;

                    .anticon {
                        margin-top: 6px !important;
                        font-size : 28px !important;
                    }

                    .ant4-menu-title-content {
                        // display: none !important;
                        width      : 0;
                        margin-left: 0;
                    }
                }
            }

        }


        &.white {
            background: white;

            .layout-classic-content-sider {
                box-shadow  : unset;
                border-right: 1px solid #f1f1f1;

            }
        }

        &.grey {
            // background: white;

            .layout-classic-content-sider {
                box-shadow  : unset;
                border-right: 1px solid #f1f1f1;
            }
        }
    }
}


.layout-classic {

    .ant4-layout-header.layout-classic-header {


        &.default {
            background:~'var(--@{ant-prefix}-primary-color)';

            .ant4-menu-horizontal.ant4-menu-dark {
                background        : transparent;
                // .ant4-menu-item {
                //     display    : flex;
                //     align-items: center;
                //     font-size  : 15px;
                // }

                .ant4-menu-item-selected {
                    background-color:~'var(--@{ant-prefix}-primary-7)';
                }

                .ant4-menu-item-active {
                    background-color:~'var(--@{ant-prefix}-primary-7)';
                }
            }

            .primary-header-dropdown {

                &:hover {
                    background-color:~'var(--@{ant-prefix}-primary-7)';
                }

                &.ant4-dropdown-open {
                    background-color:~'var(--@{ant-prefix}-primary-7)';
                }
            }

            .primary-header-button {
                color: rgba(255, 255, 255, 0.65);

                &:hover {
                    background-color:~'var(--@{ant-prefix}-primary-7)';
                    color           : white;
                }
            }

            .ant4-avatar.login {
                background: rgba(255, 255, 255, .17);
                color     : white;
            }
        }

        &.dark {
            .ant4-menu-horizontal.ant4-menu-dark {
                background        : transparent;
                // .ant4-menu-item {
                //     display    : flex;
                //     align-items: center;
                //     font-size  : 15px;
                // }

                .ant4-menu-item-selected {
                    background-color:~'var(--@{ant-prefix}-primary-color)';
                }

                .ant4-menu-item-active {
                    background-color:~'var(--@{ant-prefix}-primary-color)';
                }
            }

            .primary-header-dropdown {

                &:hover {
                    background-color:~'var(--@{ant-prefix}-primary-color)';
                }

                &.ant4-dropdown-open {
                    background-color:~'var(--@{ant-prefix}-primary-color)';
                }
            }

            .primary-header-button {
                color: rgba(255, 255, 255, 0.65);

                &:hover {
                    background-color:~'var(--@{ant-prefix}-primary-color)';
                    color           : white;
                }
            }

            .ant4-avatar.login {
                background: rgba(255, 255, 255, .17);
                color     : white;
            }
        }

        &.light {
            .ant4-menu-horizontal.ant4-menu-light {
                background   : transparent;
                border-bottom: 0;

                .ant4-menu-item-selected {
                    background-color:~'var(--@{ant-prefix}-primary-1)';
                }

                .ant4-menu-item-active {
                    background-color:~'var(--@{ant-prefix}-primary-1)';
                }

                .ant4-menu-item.ant4-menu-item-only-child {
                    padding: 0 18px;
                    margin : 0 2px;
                }

                .ant4-menu-submenu.ant4-menu-submenu-horizontal {
                    padding: 0 18px;
                    margin : 0 2px;
                }
            }

            .primary-header-dropdown {

                &:hover {
                    color           :~'var(--@{ant-prefix}-primary-color)';
                    background-color:~'var(--@{ant-prefix}-primary-1)';
                }

                &.ant4-dropdown-open {
                    color           :~'var(--@{ant-prefix}-primary-color)';
                    background-color:~'var(--@{ant-prefix}-primary-1)';
                }
            }

            .primary-header-button {
                color: rgba(0, 0, 0, 0.65);

                &.strong {
                    color: black;
                }

                &:hover {
                    color           :~'var(--@{ant-prefix}-primary-color)';
                    background-color:~'var(--@{ant-prefix}-primary-1)';
                }
            }

            .ant4-avatar.login {
                background:~'var(--@{ant-prefix}-primary-color)';
                color     : white;
            }
        }
    }

    .layout-classic-content {

        .layout-classic-content-sider {

            .ant4-menu-inline-collapsed {

                .ant4-menu-submenu-selected {
                    background-color:~'var(--@{ant-prefix}-primary-1)';
                }

                .ant4-menu-submenu-title {}
            }

        }
    }
}

.ant4-menu-dark.default.header-menu {
    background:~'var(--@{ant-prefix}-primary-color)';

    .ant4-menu-vertical {
        background: transparent;
    }

    .ant4-menu-submenu-open {
        background-color:~'var(--@{ant-prefix}-primary-7)' !important;
    }

    .ant4-menu-submenu-selected {
        color           : white !important;
        background-color:~'var(--@{ant-prefix}-primary-7)' !important;
    }


    .ant4-menu-submenu-title {
        a {
            color: unset;
        }
    }

    .ant4-menu-item {
        a {
            color: unset;
        }

        a:hover {
            color: unset;
        }
    }

    &.ant4-menu-submenu-popup {
        .ant4-menu-item {
            margin-bottom: 0 !important;
            margin-top   : 0 !important;
            height       : 50px !important;
            line-height  : 50px !important;
        }

        .ant4-menu-item-selected {
            background-color:~'var(--@{ant-prefix}-primary-7)' !important;
        }

        .ant4-menu-item-active {
            background-color:~'var(--@{ant-prefix}-primary-7)' !important;
        }
    }

    &.header-menu-popup {

        .ant4-menu-submenu-title,
        .ant4-menu-item {
            margin-bottom: 0 !important;
            margin-top   : 0 !important;
            height       : 50px !important;
            line-height  : 50px !important;
        }

        .ant4-menu-item-selected {
            background-color:~'var(--@{ant-prefix}-primary-7)' !important;
        }

        .ant4-menu-item-active {
            background-color:~'var(--@{ant-prefix}-primary-7)' !important;
        }
    }
}

.ant4-menu-dark.dark.header-menu {

    background: #3d4146;

    .ant4-menu-sub {
        background: transparent;
    }

    .ant4-menu-submenu-open {
        background-color:~'var(--@{ant-prefix}-primary-color)' !important;
    }

    .ant4-menu-submenu-selected {
        color           : white !important;
        background-color:~'var(--@{ant-prefix}-primary-color)' !important;
    }

    .ant4-menu-submenu-title {
        a {
            color: unset;
        }
    }

    .ant4-menu-item {
        a {
            color: unset;
        }

        a:hover {
            color: unset;
        }
    }

    &.ant4-menu-submenu-popup {
        .ant4-menu-item {
            margin-bottom: 0 !important;
            margin-top   : 0 !important;
            height       : 50px !important;
            line-height  : 50px !important;
        }

        .ant4-menu-item-selected {
            background-color:~'var(--@{ant-prefix}-primary-color)' !important;
        }

        .ant4-menu-item-active {
            background-color:~'var(--@{ant-prefix}-primary-color)' !important;
        }
    }

    &.header-menu-popup {

        .ant4-menu-submenu-title,
        .ant4-menu-item {
            margin-bottom: 0 !important;
            margin-top   : 0 !important;
            height       : 50px !important;
            line-height  : 50px !important;
        }

        .ant4-menu-item-selected {
            background-color:~'var(--@{ant-prefix}-primary-color)' !important;
        }

        .ant4-menu-item-active {
            background-color:~'var(--@{ant-prefix}-primary-color)' !important;
        }
    }
}

.ant4-menu-light.light.header-menu {

    >li {
        top: 0;
    }

    .ant4-menu-submenu-open {
        background-color:~'var(--@{ant-prefix}-primary-1)' !important;
    }

    .ant4-menu-submenu-selected {
        background-color:~'var(--@{ant-prefix}-primary-1)' !important;
    }

    &.ant4-menu-submenu-popup {
        .ant4-menu-item {
            margin-bottom: 0 !important;
            margin-top   : 0 !important;
            height       : 50px !important;
            line-height  : 50px !important;
        }

        .ant4-menu-item-selected {
            background-color:~'var(--@{ant-prefix}-primary-1)' !important;
        }

        .ant4-menu-item-active {
            background-color:~'var(--@{ant-prefix}-primary-1)' !important;
        }
    }

    &.header-menu-popup {

        .ant4-menu-submenu-title,
        .ant4-menu-item {
            margin-bottom: 0 !important;
            margin-top   : 0 !important;
            height       : 50px !important;
            line-height  : 50px !important;
        }

        .ant4-menu-item-selected {
            background-color:~'var(--@{ant-prefix}-primary-1)' !important;
        }

        .ant4-menu-item-active {
            background-color:~'var(--@{ant-prefix}-primary-1)' !important;
        }
    }
}

// .layout-classic-content-sider-menu-popup {
//     .ant4-menu-submenu-selected {
//         .ant4-menu-submenu-title {
//             background-color: @menu-item-active-bg ;
//         }
//     }
// }


.layout-classic-content-sider-drawer {
    .addons {
        .ant4-avatar.login {
            background:~'var(--@{ant-prefix}-primary-color)';
            color     : white;
        }
    }
}

.layout-classic-content-sider-drawer {

    .ant4-drawer-body {
        padding: 0;

        .layout-classic-content-sider {
            padding-top   : 7px;
            display       : flex;
            flex-direction: column;

            .sider-title {
                font-size       : 22px;
                padding         : 15px 20px 10px 30px;
                margin-bottom   : 10px;
                white-space     : nowrap;
                -o-text-overflow: ellipsis;
                text-overflow   : ellipsis;
                overflow        : hidden;
            }

            &.ant4-layout-sider-collapsed {
                .sider-title {
                    visibility: hidden;

                    &:first-letter {
                        visibility: visible !important;
                    }
                }
            }


            .anticon {
                transition: font-size 0.3s;
            }

            .ant4-layout-sider-children {
                flex      : 1 1;
                overflow-y: auto;
                overflow-x: hidden;
            }

            .ant4-menu.ant4-menu-inline {
                // width             : calc(100% + 1px);
                border-right-color: transparent;

                .ant4-menu-sub {
                    .ant4-menu-item {
                        >.ant4-menu-title-content {
                            margin-left: 12px;
                        }
                    }

                    .ant4-menu-submenu {
                        >.ant4-menu-submenu-title {
                            >.ant4-menu-title-content {
                                margin-left: 12px;
                            }
                        }
                    }
                }

                .ant4-menu-item {
                    margin-bottom : 2px;
                    margin-top    : 2px;
                    line-height   : 44px;
                    height        : 44px;
                    display       : flex;
                    flex-direction: row;
                    align-items   : center;

                    .anticon {
                        font-size: 26px;
                    }
                }

                .ant4-menu-item:after {
                    right: auto;
                    left : 0;
                }

                .ant4-menu-submenu-title {
                    margin-bottom : 2px;
                    margin-top    : 2px;
                    line-height   : 44px;
                    height        : 44px;
                    display       : flex;
                    flex-direction: row;
                    align-items   : center;

                    .anticon {
                        font-size: 26px;
                    }
                }

                .ant4-menu-item-group {
                    margin-bottom: 10px;

                    .ant4-menu-item-group-title {
                        padding-left: 24px;
                    }
                }


                .ant4-menu-sub.ant4-menu-inline {
                    background: transparent;
                }
            }

            .ant4-menu-inline-collapsed {
                width             : calc(100% + 1px);
                border-right-color: transparent;

                .ant4-menu-item {
                    margin-bottom  : 2px;
                    margin-top     : 2px;
                    line-height    : 44px;
                    height         : 44px;
                    display        : flex;
                    flex-direction : row;
                    align-items    : center;
                    justify-content: center;
                    padding        : 0 !important;
                    padding-left   : 0 !important;

                    .ant4-menu-item-icon {
                        margin-top: 4px !important;
                        font-size : 28px !important;
                    }
                }

                .ant4-menu-item-group {
                    margin-bottom: 10px;

                    .ant4-menu-item-group-title {
                        padding-left: 24px;
                    }
                }

                .ant4-menu-submenu-title {
                    margin-bottom  : 2px;
                    margin-top     : 2px;
                    line-height    : 44px;
                    height         : 44px;
                    display        : flex;
                    flex-direction : row;
                    align-items    : center;
                    justify-content: center;
                    padding        : 0 !important;
                    padding-left   : 0 !important;

                    .anticon {
                        margin-top: 6px !important;
                        font-size : 28px !important;
                    }
                }
            }

        }

        .layout-classic-content-sider.right {

            .ant4-menu.ant4-menu-inline {
                .ant4-menu-item:after {
                    right: 0;
                    left : auto;
                }

                .ant4-menu-sub {
                    .ant4-menu-item {
                        >.ant4-menu-title-content {
                            margin-left: 0px;
                        }
                    }

                    .ant4-menu-submenu {
                        >.ant4-menu-submenu-title {
                            >.ant4-menu-title-content {
                                margin-left: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.layout-classic-content-sider-menu-popup {

    .anticon {
        font-size: 26px !important;
    }

    .ant4-menu-item {
        margin-bottom : 2px !important;
        margin-top    : 2px !important;
        line-height   : 44px !important;
        height        : 44px !important;
        display       : flex !important;
        flex-direction: row !important;
        align-items   : center !important;
    }

    .ant4-menu-submenu-title {
        margin-bottom : 2px !important;
        margin-top    : 2px !important;
        line-height   : 44px !important;
        height        : 44px !important;
        display       : flex !important;
        flex-direction: row !important;
        align-items   : center !important;
    }
}

.primary-header-dropdown-popup-menu {
    .ant4-dropdown-menu-item {
        display    : flex;
        align-items: center;

        .anticon {
            font-size: 18px !important;
            color    : #ababab;
        }
    }
}


.layout-classic-content-sider-menu-popup {
    .ant4-menu-submenu-selected {
        .ant4-menu-submenu-title {
            background-color:~'var(--@{ant-prefix}-primary-1)';
        }
    }
}


@media screen and (max-width: 768px) {
    .layout-classic {
        .ant4-layout-header.layout-classic-header {
            >.header-content {

                .app-title {
                    flex              : 1 1;
                    padding           : 0 10px;
                    // justify-content: center;

                    .title {
                        text-align  : left;
                        width       : 0;
                        // font-size: 18px !important;
                    }
                }

                .menu-container {
                    flex: 0 1;

                    .ant4-menu {
                        margin-left: 0 !important;
                    }
                }

                .addons {
                    padding-right: 0 !important;
                }
            }
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;