@import '@yulintu/freesia-design/css/variables.less';

.unread-title {
    display         : flex;
    align-items     : center;
    justify-content : center;
    background-color: rgb(231, 97, 98);
    color           : white;
    padding         : 2px 10px 4px 10px;
    font-size       : 20px;
    line-height     : 20px;
    border-radius   : 15px;
    font-weight     : 500;
    margin-top      : 2px;
}

.primary-header-button.message-observer {
    min-width      : 58px;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.message-observer-drawer {

    .ant4-drawer-body {
        padding : 0;
        overflow: hidden;

        .observer-panel {
            padding      : 5px 0 0 0;
            margin       : 0px 0;
            height       : 100%;
            // min-height: 300px;
            // max-height: 700px;

            .header {
                padding: 10px 20px;

                .title {
                    font-size   : 22px;
                    margin-right: 10px;
                }

                .unread-title {
                    font-size     : 18px;
                    line-height   : 18px;
                    margin-top    : 2px;
                    padding-bottom: 3px;
                }
            }

            .observer-item {
                margin       : 0px 15px;
                padding      : 15px 0 12px 0;
                border-bottom: 1px solid #eee;


                .ant4-progress {
                    .anticon {
                        font-size: 18px;
                    }
                }
            }

            .message-item {

                .status-text {
                    display       : flex;
                    flex-direction: row;
                    align-items   : flex-start;

                    .anticon {
                        color: rgb(200, 200, 200);
                    }

                    .info {
                        display       : flex;
                        flex-direction: column;
                        flex          : 1 1;
                        width         : 0;
                        margin-left   : 5px;


                        .name-row {
                            display       : flex;
                            flex-direction: row;
                            align-items   : center;

                            .state {
                                border-radius       : 100%;
                                width               : 6px;
                                height              : 6px;
                                background-color    :~'var(--@{ant-prefix}-info-color)';
                                // margin-top       : 2px;
                                margin-right        : 7px;
                                margin-left         : 10px;
                            }

                            .state.error {
                                background-color :~'var(--@{ant-prefix}-error-color)';
                            }

                            .state.success {
                                background-color :~'var(--@{ant-prefix}-success-color)';
                            }

                            .state.warning {
                                background-color :~'var(--@{ant-prefix}-warning-color)';
                            }

                            .name {
                                width      : 0;
                                flex       : 1 1;
                                font-size  : 15px;
                                font-weight: bold;
                            }
                        }

                        .description {
                            opacity      : 1;
                            font-size    : 13px;
                            margin-bottom: 3px;
                            margin-top   : 3px;
                            color        : black;
                        }
                    }

                    .time {
                        display       : flex;
                        flex-direction: row;
                        align-items   : center;

                        .key {
                            opacity  : .7;
                            font-size: 13px;
                        }

                        .value {
                            margin-left: 3px;
                            opacity    : .7;
                            font-size  : 13px;
                        }

                        .duration {
                            flex: 1 1;
                        }
                    }
                }
            }

            .ant4-pagination {
                margin: 12px;
                width : 100%;
            }

            .nodata {
                // margin-top: 80px;
            }
        }

    }
}
@ant-prefix: ant4;@root-entry-name: variable;