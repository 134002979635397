.tasks-observer-drawer,
.tasks-observer-popup {
    .upload-panel {

        .upload-item {
            .status-text {
                display       : flex;
                flex-direction: row;
                align-items   : center;

                .info {
                    display       : flex;
                    flex-direction: column;
                    flex          : 1 1;
                    width         : 0;
                    margin-left   : 10px;

                    .name-row {
                        display       : flex;
                        flex-direction: row;
                        align-items   : center;

                        .name {
                            width: 0;
                            flex : 1 1;
                        }
                    }

                    .description {
                        opacity      : .7;
                        font-size    : 13px;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;