.ant4-modal.backstage-modal,
.ant4-modal.modal-backstage {
    .ant4-modal-content {
        background-color: rgb(244, 245, 245);

        >.ant4-modal-header {
            border-bottom: 0px;
            background   : transparent;
        }

        >.ant4-modal-footer {
            border-top: 0;

            >.ant4-btn {
                height   : 37px;
                min-width: 80px;
            }
        }
    }

    &.small {

        .ant4-modal-content {

            >.ant4-modal-footer {

                >.ant4-btn {
                    height: 35px;
                }
            }
        }
    }
}

.ant4-modal.backstage-modal.white,
.ant4-modal.modal-backstage.white {
    .ant4-modal-content {
        background-color: white;
    }
}

.ant4-modal.modal-backstage.title-icon {

    .ant4-modal-header {

        padding: 13px 24px;

        .ant4-modal-title {
            .title {
                display       : flex;
                flex-direction: row;
                align-items   : center;

                .anticon {
                    font-size   : 32px;
                    margin-right: 7px;
                }
            }
        }
    }

}

.ant4-layout.full {
    width : 100%;
    height: 100%;
}

.ant4-spin-nested-loading.full {
    width : 100%;
    height: 100%;

    .ant4-spin {
        max-height: unset !important;
    }

    >.ant4-spin-container {
        width : 100%;
        height: 100%;
    }
}

.ant4-spin-nested-loading.full.abs0 {
    top     : 0;
    right   : 0;
    left    : 0;
    bottom  : 0;
    position: absolute !important;
}

.question.anticon {
    font-size: 16px;
    color    :~'var(--@{ant-prefix}-primary-color)';
}

.action-bar {
    display       : flex;
    flex-direction: row;
    align-items   : center;
    overflow-x    : auto;

    >.ant4-divider-vertical {
        height: 38px;
        margin: 2px 20px 0px 20px;
    }

    >.ant4-btn {
        height         : 37px;
        display        : flex;
        flex-direction : row;
        align-items    : center;
        justify-content: center;
    }

    .anticon {
        font-size: 16px;
    }

    >.fill {
        flex: 1 1;
    }

    button.greyicon {
        >.anticon {
            color: #ababab;
        }
    }
}

.ant4-layout-header.action-bar {
    height     : 67px;
    padding    : 10px 33px;
    background : transparent;
    line-height: 1.5;
}

.ant4-input-search.full {
    height: 100%;

    .ant4-input {
        font-size: 16px;
    }

    .ant4-input-wrapper {

        height: 100%;

        .ant4-input-affix-wrapper {
            height: 100%;
        }
    }
}

.ant4-input-search.large {

    .ant4-input-prefix {
        margin-right: 10px;
    }

    .ant4-input-suffix {
        .ant4-input-clear-icon {
            font-size: 16px;
        }
    }
}

.ant4-input-search.outlined {
    .ant4-input {
        background-color: transparent;
    }

    .ant4-input-affix-wrapper {
        border          : 0;
        background-color: transparent;
    }

    .ant4-input-group-addon {
        display         : none;
        background-color: transparent;
    }
}

.tag--success {
    height          : 24px;
    padding         : 2px 12px;
    border-radius   : 15px;
    font-size       : 13px;
    background-color:~'var(--@{ant-prefix}-success-color)';
    white-space     : nowrap;
    color           : white;
}

.tag--error {
    height          : 24px;
    padding         : 2px 12px;
    border-radius   : 15px;
    font-size       : 13px;
    background-color:~'var(--@{ant-prefix}-error-color)';
    white-space     : nowrap;
    color           : white;
}

.tag--info {
    height          : 24px;
    padding         : 2px 12px;
    border-radius   : 15px;
    font-size       : 13px;
    background-color:~'var(--@{ant-prefix}-info-color)';
    white-space     : nowrap;
    color           : white;
}

.tag--normal {
    height          : 24px;
    padding         : 2px 12px;
    border-radius   : 15px;
    font-size       : 13px;
    background-color: fade(black, 7%);
    white-space     : nowrap;
    color           : #686868;
}

.tag--primary-1 {
    height          : 24px;
    padding         : 2px 12px;
    border-radius   : 15px;
    font-size       : 13px;
    background-color:~'var(--@{ant-prefix}-primary-1)';
    white-space     : nowrap;
    color           :~'var(--@{ant-prefix}-primary-color)';
}

.tag--primary-2 {
    height          : 24px;
    padding         : 2px 12px;
    border-radius   : 15px;
    font-size       : 13px;
    background-color:~'var(--@{ant-prefix}-primary-2)';
    white-space     : nowrap;
    color           :~'var(--@{ant-prefix}-primary-color)';
}

.ant4-tag.modal-title-tag {
    padding  : 3px 7px;
    font-size: 14px;
}

.panel {
    border          : 1px solid rgba(36, 41, 46, .12);
    border-radius   : 4px;
    background-color: white;
}

.panel--normal {
    padding: 20px;
}

.panel.hoverable:hover {
    border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
    background-color: white;
}

.panel.hoverable:active {
    border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
    background-color:~'var(--@{ant-prefix}-primary-1)';
}

.item-selected {
    .panel.hoverable:hover {
        background-color:~'var(--@{ant-prefix}-primary-1)';
        border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
    }

    .panel.hoverable:active {
        background-color:~'var(--@{ant-prefix}-primary-1)';
        border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
    }

    .panel.hoverable {
        background-color:~'var(--@{ant-prefix}-primary-1)';
        border          : 1px solid~'var(--@{ant-prefix}-primary-4)';
    }

    .project-item--template-gallery-item {
        >.thumb {
            background-color:~'var(--@{ant-prefix}-primary-4)' !important;
        }
    }
}

.bg-primary {
    background-color:~'var(--@{ant-prefix}-primary-color)';
}

.text-primary {
    color:~'var(--@{ant-prefix}-primary-color)';
}

.text-second {
    color    : rgb(91 95 98);
    font-size: 13px;
}

.text-third {
    color    : #9E9E9E;
    font-size: 13px;
}

.text-error {
    color:~'var(--@{ant-prefix}-error-color)';
}

.text-success {
    color:~'var(--@{ant-prefix}-success-color)';
}

.text-warning {
    color:~'var(--@{ant-prefix}-warning-color)';
}

.text-info {
    color:~'var(--@{ant-prefix}-info-color)';
}

.text-grey {
    color: #00000080;
}

.text-code {
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

.ant4-tabs.gradient-ink-bar {

    .ant4-tabs-tab {
        padding: 12px 24px;
    }

    .ant4-tabs-ink-bar {
        background-color:~'var(--@{ant-prefix}-primary-color)';
        height          : 4px !important;
        border-radius   : 2px;
    }
}

.btn-text-link {
    padding: 0 !important;
    height : auto !important;
}

.btn-bd-none {
    border-width: 1px;
    border-color: transparent !important;
    box-shadow  : none !important;
}

.ant4-dropdown-menu-item {
    >.anticon {
        font-size: 16px;
    }
}

.ant4-tabs-top.full {
    .ant4-tabs-content-top {
        height  : 100%;
        overflow: auto;
        position: relative;

        .ant4-tabs-tabpane {
            height: 100%;
        }
    }
}

.ant4-spin-spinning.full {
    width          : 100%;
    height         : 100%;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.ant4-notification-notice-error.error-background {
    background-color:~'var(--@{ant-prefix}-error-color)';
    color           : #ffffffb8;

    .ant4-notification-notice-message {
        font-size: 22px;
        color    : white;
    }

    .ant4-notification-notice-icon-error {
        color    : #ffffffb8;
        font-size: 28px;
    }

    .ant4-notification-notice-close {
        color: #ffffff90;
    }
}

.ant4-notification-notice-success.success-background {
    background-color:~'var(--@{ant-prefix}-success-color)';
    color           : #ffffffb8;

    .ant4-notification-notice-message {
        font-size: 22px;
        color    : white;
    }

    .ant4-notification-notice-icon-success {
        color    : #ffffffb8;
        font-size: 28px;
    }

    .ant4-notification-notice-close {
        color: #ffffff90;
    }
}

.ant4-notification-notice-info.info-background {
    background-color:~'var(--@{ant-prefix}-info-color)';
    color           : #ffffffb8;

    .ant4-notification-notice-message {
        font-size: 22px;
        color    : white;
    }

    .ant4-notification-notice-icon-info {
        color    : #ffffffb8;
        font-size: 28px;
    }

    .ant4-notification-notice-close {
        color: #ffffff90;
    }
}

.ant4-notification-notice-warning.warn-background {
    background-color:~'var(--@{ant-prefix}-warning-color)';
    color           : #ffffffb8;

    .ant4-notification-notice-message {
        font-size: 22px;
        color    : white;
    }

    .ant4-notification-notice-icon-warning {
        color    : #ffffffb8;
        font-size: 28px;
    }

    .ant4-notification-notice-close {
        color: #ffffff90;
    }
}

.ant4-message {
    .anticon {
        font-size: 18px;
    }
}

.ant4-pro-page-container.page {

    .ant4-pro-page-container-children-content {
        margin : 0;
        padding: 24px 24px 0;

        >.ant4-breadcrumb {
            margin-bottom: 24px;
        }
    }

    &.full {
        width : 100%;
        height: 100%;

        .ant4-pro-grid-content {

            width : 100%;
            height: 100%;

            .ant4-pro-grid-content-children {

                width : 100%;
                height: 100%;

                .ant4-pro-page-container-children-content {
                    width : 100%;
                    height: 100%;

                    display       : flex;
                    flex-direction: column;

                    >.fill {
                        flex  : 1 1;
                        height: 0;
                    }

                    >.fill.ant4-card {
                        >.ant4-card-body {
                            padding-top: 20px;
                            width      : 100%;
                            height     : 100%;
                        }
                    }
                }
            }
        }

    }

    &.breadcrumb {

        .ant4-pro-page-container-children-content {
            padding: 20px 24px 0;

            >.ant4-breadcrumb {
                margin-bottom: 20px;
            }
        }
    }


    .main-card {
        .ant4-card-body {
            padding-top: 20px;
        }
    }

    .main-layout {
        width           : 100%;
        height          : 100%;
        display         : flex;
        flex-direction  : column;
        background      : transparent !important;
        background-color: transparent;

        >.ant4-layout-header.action-bar {
            padding: 3px 0;
            height : 46px;
        }

        >.ant4-pro-table {
            .ant4-pro-card-body {
                padding: 0;

                .ant4-pro-table-alert {
                    display: none;
                }

                .ant4-pro-table-list-toolbar-container {
                    padding-top: 0;

                    .ant4-pro-table-list-toolbar-left {
                        flex : 1 1;
                        width: 0;

                        .ant4-pro-table-list-toolbar-title {
                            width: 100%;

                            >.action-bar {
                                padding    : 3px 0;
                                width      : 100%;
                                height     : 46px;
                                line-height: 1.5;
                            }
                        }
                    }

                }

            }

            .ant4-pro-core-field-label-icon.ant4-pro-core-field-label-close {
                margin-top: 0;
            }

            .ant4-pagination {
                margin: 20px 0 0 0;
            }
        }

        >.main {
            flex         : 1 1;
            height       : 0;
            min-height   : 380px;
            overflow-y   : auto;
            margin-top   : 10px;
            margin-bottom: 10px;

        }

        >.ant4-pagination {
            margin-top: 10px;
        }
    }
}

@media screen and (max-width: 500px) {
    .ant4-pro-page-container.page {
        &.breadcrumb {

            .ant4-pro-page-container-children-content {

                >.ant4-breadcrumb {
                    margin-left: 30px;
                }
            }
        }
    }
}

.ant4-pro-table.default,
.ant4-table-wrapper.default {
    .ant4-table-thead {
        .ant4-table-cell {
            padding         : 12px 14px;
            background-color: transparent;
            font-weight     : bold;
        }
    }

    .ant4-table-tbody {
        .ant4-table-cell {
            padding: 12px 14px;
        }

        .ant4-table-cell-row-hover {
            background-color:~'var(--@{ant-prefix}-primary-1)';
        }

        .ant4-table-row-selected {
            .ant4-table-cell {
                background-color:~'var(--@{ant-prefix}-primary-2)';
            }
        }
    }
}

.ant4-table-wrapper {
    .td-link-button {
        >span {
            height       : auto;
            width        : 100%;
            overflow     : hidden;
            text-overflow: ellipsis;
            word-break   : keep-all;
            white-space  : nowrap;
            text-align   : left;
        }

        height        : auto;
        display       : flex;
        flex-direction: row;
        align-items   : center;

        padding : 0;
    }

    .td-text {
        overflow     : hidden;
        text-overflow: ellipsis;
        word-break   : keep-all;
        white-space  : nowrap;
    }
}

div.full {
    width : 100%;
    height: 100%;
}

.ant4-card.full {

    .ant4-card-body {
        width : 100%;
        height: 100%;
    }
}

.ant4-card {

    .card-title {
        font-size  : 22px;
        font-weight: 400;
        color      : #000;
    }
}


.ant4-layout.transparent {
    background-color: transparent;
}

.ant4-pagination.right {
    display        : flex;
    flex-direction : row;
    justify-content: flex-end;
}

.btn-bk-hover {
    background-color: transparent !important;
}

.btn-bk-hover:hover {
    background-color:~'var(--@{ant-prefix}-primary-2)' !important;
}

.btn-bk-hover:active {
    background-color:~'var(--@{ant-prefix}-primary-3)' !important;
}

.btn-pd-lg {
    padding: 20px;
}

.btn-pd-md {
    padding: 15px;
}

.btn-pd {
    padding: 10px;
}

.btn-pd-sm {
    padding: 5px;
}

.pd-sm {
    padding: 5px;
}

.pd-md {
    padding: 10px;
}

.pd {
    padding: 15px;
}

.pd-lg {
    padding: 20px;
}

.pd-v-sm {
    padding-top   : 5px;
    padding-bottom: 5px;
}

.pd-v-md {
    padding-top   : 10px;
    padding-bottom: 10px;
}

.pd-v {
    padding-top   : 15px;
    padding-bottom: 15px;
}

.pd-v-lg {
    padding-top   : 20px;
    padding-bottom: 20px;
}

.pd-h-sm {
    padding-left : 5px;
    padding-right: 5px;
}

.pd-h-md {
    padding-left : 10px;
    padding-right: 10px;
}

.pd-h {
    padding-left : 15px;
    padding-right: 15px;
}

.pd-h-lg {
    padding-left : 20px;
    padding-right: 20px;
}

.pd10 {
    padding: 10px;
}

.pdl10 {
    padding-left: 10px;
}

.pdr10 {
    padding-right: 10px;
}

.pd5 {
    padding: 5px;
}

.pd0 {
    padding: 0px;
}

.mg0 {
    margin: 0px;
}

.mg10 {
    margin: 10px;
}

.mgb2 {
    margin-bottom: 2px;
}

.mgb1 {
    margin-bottom: 1px;
}

.mgb3 {
    margin-bottom: 3px;
}

.mgb5 {
    margin-bottom: 5px;
}

.mgb10 {
    margin-bottom: 10px;
}

.mgb20 {
    margin-bottom: 20px;
}

.mgb30 {
    margin-bottom: 30px;
}

.mgr10 {
    margin-right: 10px;
}

.mgr5 {
    margin-right: 5px;
}

.mgr20 {
    margin-right: 20px;
}

.mgr30 {
    margin-right: 30px;
}

.mgr25 {
    margin-right: 25px;
}

.mgr15 {
    margin-right: 15px;
}

.mgr50 {
    margin-right: 50px;
}

.mgr60 {
    margin-right: 60px;
}

.mgr40 {
    margin-right: 40px;
}

.mgl5 {
    margin-left: 5px;
}

.mgl10 {
    margin-left: 10px;
}

.mgl15 {
    margin-left: 15px;
}

.mgl20 {
    margin-left: 20px;
}

.mgl25 {
    margin-left: 25px;
}

.mgl30 {
    margin-left: 30px;
}

.mgb25 {
    margin-bottom: 25px;
}

.mgt1 {
    margin-top: 1px !important;
}

.mgt2 {
    margin-top: 2px !important;
}

.mgt3 {
    margin-top: 3px !important;
}

.mgt5 {
    margin-top: 5px !important;
}

.mgt10 {
    margin-top: 10px !important;
}

.mgt20 {
    margin-top: 20px;
}


.mgt25 {
    margin-top: 25px;
}

.mgt30 {
    margin-top: 30px;
}

.pgt20 {
    padding-top: 20px;
}

.pgt10 {
    padding-top: 10px;
}

.mw80 {
    min-width: 80px;
}



.card {
    border          : 0 !important;
    border-radius   : 2px;
    background-color: white;

    &.shadow12 {
        box-shadow: 0 1px 4px #00000012;
    }

    &.shadow10 {
        box-shadow: 0 1px 4px #0000000a;
    }

    &.card-propertygrid {
        padding: 20px 0 20px 20px;
        height : 100%;
    }
}

.card-settings {
    padding: 32px 45px 45px 45px;

    .card-title,
    .card-settings-title {
        font-size       : 22px;
        font-weight     : 400;
        color           : #000;
        margin-left     : 0;
        // margin-bottom: 25px;
    }
}

.card-info {
    padding: 20px 24px;

    .card-title {
        font-size       : 22px;
        font-weight     : 400;
        color           : #000;
        margin-left     : 0;
        // margin-bottom: 25px;
    }
}

.ant4-modal {

    .ant4-modal-body {
        .panel--propertygrid {
            padding: 20px 0 20px 20px;
        }
    }
}

.ant4-tabs.ant4-tabs-top.full {

    .ant4-tabs-nav {
        margin-bottom: 0;
        padding-left : 10px;
    }

    // .ant4-tabs-content {
    //     background-color: white;
    // }

}

.ant4-tabs.lg {
    .ant4-tabs-tab-btn {
        font-size: 16px;
    }
}

.ant4-table-wrapper {
    .row-al {
        background-color: rgb(250, 250, 250);
    }
}

.ant4-alert {
    >.ant4-alert-icon {
        font-size: 32px !important;
    }

    >.ant4-alert-content {
        .ant4-alert-message {
            color    : black;
            font-size: 18px;
        }

        .ant4-alert-description {
            color: rgba(0, 0, 0, .6);
        }
    }
}

// .text-third {
//     color: #d8d8d8;
// }

.bg-hover {
    background-color:~'var(--@{ant-prefix}-primary-1)';
}

.bg-active {
    background-color:~'var(--@{ant-prefix}-primary-2)';
}

.bg-active2 {
    background-color:~'var(--@{ant-prefix}-primary-3)';
}


.ant4-card.shadow12 {
    box-shadow: 0 1px 4px #00000012;
}

.ant4-card.flex {
    display       : flex;
    flex-direction: column;

    .ant4-card-body {
        position: relative;
        height  : 0;
        flex    : 1 1;
    }
}

.ant4-card.pd0 {
    .ant4-card-body {
        padding: 0;
    }
}

.ant4-progress.lg {
    .anticon {
        font-size: 18px;
    }
}

.ant4-progress.has-warn {
    .anticon-check-circle {
        color:~'var(--@{ant-prefix}-warning-color)';
    }
}

.button--clear-selection {
    padding-left : 0 !important;
    padding-right: 0 !important;
}

.minw {
    // min-width: 800px;
}

a.themeable {
    color :~'var(--@{ant-prefix}-primary-color)' !important;

    &:hover {
        color:~'var(--@{ant-prefix}-primary-color-hover)' !important;
    }

    &:active {
        color:~'var(--@{ant-prefix}-primary-color-active)' !important;
    }
}

.ant4-badge-count {
    background: rgb(231, 97, 98) !important;
    box-shadow: 0 0 0 1.5px #ffffffc9 !important;
}

.ant4-badge-count:not(.ant4-badge-count-sm) {
    font-size: 14px !important;
}

.ant4-badge-count-sm {
    height     : 17px !important;
    font-size  : 12px !important;
    line-height: 14px !important;
    padding    : 1px 8px 1px 8px !important;
    top        : -1px !important;
}

.ant4-menu-submenu-title,
.ant4-menu-item {
    .unread {
        background-color: rgb(231, 97, 98);
        color           : white;
        padding         : 0px 7px 1px 7px;
        border-radius   : 10px;
        font-weight     : 500;
        margin-left     : 10px;
        font-size       : 12px;
    }
}

.ant4-radio-group.default {

    .ant4-radio-button-wrapper {
        border     : 0;
        padding-top: 1px;
    }

    .ant4-radio-button-wrapper:not(:last-child) {
        border-right: 1px solid #f1f1f1;
    }

    .ant4-radio-button-wrapper:not(:first-child)::before {
        width: 0px;
    }
}

.ant4-tree-directory.default {
    .ant4-tree-treenode {
        padding    : 0 5px 0;
        height     : 35px;
        align-items: center;

        &::before {
            bottom       : 0 !important;
            border-radius: 3px;
        }

        .ant4-tree-switcher {
            display        : flex;
            align-items    : center;
            justify-content: center;
            color          : rgba(24, 24, 25, 0.32);

        }

        .ant4-tree-node-content-wrapper {
            height        : 100%;
            display       : flex;
            flex-direction: row;
            align-items   : center;

            .ant4-tree-title {
                width: 100%;
            }
        }

        &:hover::before {
            background :~'var(--@{ant-prefix}-primary-1)' !important;
            border     : 1px solid~'var(--@{ant-prefix}-primary-4)';
        }

        &.ant4-tree-treenode-selected {

            .ant4-tree-switcher {
                color: rgba(24, 24, 25, 0.55);

            }

            .ant4-tree-node-content-wrapper.ant4-tree-node-selected {
                color: rgba(0, 0, 0, .85);
            }
        }

        &.ant4-tree-treenode-selected::before {
            background :~'var(--@{ant-prefix}-primary-2)' !important;
            border     : 1px solid~'var(--@{ant-prefix}-primary-4)';
        }
    }
}