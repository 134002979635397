.freesia-design.docs-frame {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.freesia-design.docs-frame .docs-container {
  background-color: white;
  width: 100%;
  max-width: 1600px;
  padding: 20px 250px 40px 64px;
}
.freesia-design.docs-frame .docs-container .docs-affix {
  position: absolute;
  right: 50px;
  max-width: 150px;
}
.freesia-design.docs-frame .docs-container h1 {
  margin-top: 8px;
  margin-bottom: 20px;
  color: #000000d9 !important;
  font-weight: 500 !important;
  font-size: 32px !important;
  line-height: 38px !important;
}
.freesia-design.docs-frame .docs-container h2 {
  margin: 1.6em 0 0.6em;
  color: #000000d9 !important;
  font-weight: 500 !important;
  font-size: 26px !important;
  line-height: 32px !important;
}
.freesia-design.docs-frame .docs-container h3 {
  margin: 1.6em 0 0.6em;
  color: #000000 !important;
  font-weight: 500 !important;
  font-size: 22px;
  line-height: 32px !important;
}
.freesia-design.docs-frame .docs-container .ant4-typography {
  margin: 1em 0;
}
.freesia-design.docs-frame .docs-container .ant4-typography ul {
  margin-top: 5px;
}
.freesia-design.docs-frame .docs-container .ant4-typography ul li a {
  font-size: 13px;
}
.freesia-design.docs-frame .docs-container .ant4-typography blockquote .ant4-typography {
  font-size: 90%;
  padding: 5px 0;
}
.freesia-design.docs-frame .docs-container .ant4-typography blockquote .ant4-typography:not(a) {
  color: #00000080;
}
.freesia-design.docs-frame .docs-container .ant4-typography pre {
  margin: 16px 0;
  padding: 12px 20px;
  border: 0px;
  border-radius: 0;
}
.freesia-design.docs-frame .docs-container .ant4-typography .ant4-image .ant4-image-img:not(.noshadow) {
  box-shadow: 0 8px 20px #8fa8bf59;
}
.freesia-design.docs-frame .docs-container .ant4-typography iframe {
  box-shadow: 0 8px 20px #8fa8bf59;
  border: 0;
  width: 100%;
  height: 600px;
}
.freesia-design.docs-frame .docs-container article.ant4-typography {
  margin: 1em 0 3em 0;
}
@media screen and (max-width: 900px) {
  .freesia-design.docs-frame .docs-container {
    padding: 20px 40px 40px 40px;
  }
  .freesia-design.docs-frame .docs-container .docs-affix {
    visibility: collapse;
  }
}
@media screen and (max-width: 600px) {
  .freesia-design.docs-frame .docs-container {
    padding: 20px 20px 20px 20px;
  }
}
@media screen and (max-width: 500px) {
  .freesia-design.docs-frame .docs-container {
    padding: 30px 20px 20px 20px;
  }
}
