.step-wizzard {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.step-wizzard > .steps {
  margin: 5px 30px 15px 30px;
}
.step-wizzard > .steps .ant4-steps-item-finish .ant4-steps-item-icon {
  border-color: var(--ant4-success-color);
  background-color: var(--ant4-success-color);
}
.step-wizzard > .steps .ant4-steps-item-finish .ant4-steps-item-icon .ant4-steps-icon {
  color: white;
}
.step-wizzard > .steps .ant4-steps-item-icon .ant4-steps-icon {
  font-size: 20px;
}
.step-wizzard > .steps .ant4-steps-item-icon .ant4-steps-icon .anticon-check {
  margin-top: 5px;
}
.step-wizzard > .tabs {
  flex: 1 1;
  height: 0;
  overflow-x: hidden;
}
.step-wizzard > .tabs > .gradient-ink-bar > .ant4-tabs-nav {
  display: none;
}
