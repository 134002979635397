// .anticon {
//     font-size: 16px;
// }

@import './antd.less';
@import './framework.less';

body {
    >iframe {
        display: none;

    }
}

// @primary-1: ~'var(--@{ant-prefix}-primary-color)';
// @primary-1: color(~`colorPalette('@{primary-color}', 3) `);
// @primary-1: color(~`colorPalette('@{primary-color}', 8) `); // unused

// @primary-1: color(~`tinycolor(~'var(--@{ant-prefix}-primary-7)').lighten(30).toHexString() `);
@ant-prefix: ant4;@root-entry-name: variable;