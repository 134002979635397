.primary-header-button.tasks-observer {
  min-width: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tasks-observer-drawer .ant4-drawer-body,
.tasks-observer-popup .ant4-drawer-body,
.tasks-observer-drawer .ant4-popover-inner-content,
.tasks-observer-popup .ant4-popover-inner-content {
  padding: 0;
}
.tasks-observer-drawer .ant4-drawer-body .tabs,
.tasks-observer-popup .ant4-drawer-body .tabs,
.tasks-observer-drawer .ant4-popover-inner-content .tabs,
.tasks-observer-popup .ant4-popover-inner-content .tabs {
  overflow: hidden;
}
.tasks-observer-drawer .ant4-drawer-body .tabs .ant4-tabs-nav,
.tasks-observer-popup .ant4-drawer-body .tabs .ant4-tabs-nav,
.tasks-observer-drawer .ant4-popover-inner-content .tabs .ant4-tabs-nav,
.tasks-observer-popup .ant4-popover-inner-content .tabs .ant4-tabs-nav {
  margin-bottom: 0;
}
.tasks-observer-drawer .ant4-drawer-body .tabs .ant4-tabs-nav .ant4-tabs-nav-list,
.tasks-observer-popup .ant4-drawer-body .tabs .ant4-tabs-nav .ant4-tabs-nav-list,
.tasks-observer-drawer .ant4-popover-inner-content .tabs .ant4-tabs-nav .ant4-tabs-nav-list,
.tasks-observer-popup .ant4-popover-inner-content .tabs .ant4-tabs-nav .ant4-tabs-nav-list {
  margin-left: 0px;
}
.tasks-observer-drawer .ant4-drawer-body .tabs .ant4-tabs-content-top,
.tasks-observer-popup .ant4-drawer-body .tabs .ant4-tabs-content-top,
.tasks-observer-drawer .ant4-popover-inner-content .tabs .ant4-tabs-content-top,
.tasks-observer-popup .ant4-popover-inner-content .tabs .ant4-tabs-content-top {
  overflow-x: hidden;
}
.tasks-observer-drawer .ant4-drawer-body .observer-panel,
.tasks-observer-popup .ant4-drawer-body .observer-panel,
.tasks-observer-drawer .ant4-popover-inner-content .observer-panel,
.tasks-observer-popup .ant4-popover-inner-content .observer-panel {
  padding: 5px 0 0 0;
  margin: 0px 0;
  height: 100%;
}
.tasks-observer-drawer .ant4-drawer-body .observer-panel .observer-item,
.tasks-observer-popup .ant4-drawer-body .observer-panel .observer-item,
.tasks-observer-drawer .ant4-popover-inner-content .observer-panel .observer-item,
.tasks-observer-popup .ant4-popover-inner-content .observer-panel .observer-item {
  margin: 0px 20px;
  padding: 15px 0 12px 0;
  border-bottom: 1px solid #eee;
}
.tasks-observer-drawer .ant4-drawer-body .observer-panel .observer-item .ant4-progress .anticon,
.tasks-observer-popup .ant4-drawer-body .observer-panel .observer-item .ant4-progress .anticon,
.tasks-observer-drawer .ant4-popover-inner-content .observer-panel .observer-item .ant4-progress .anticon,
.tasks-observer-popup .ant4-popover-inner-content .observer-panel .observer-item .ant4-progress .anticon {
  font-size: 18px;
}
.tasks-observer-drawer .ant4-drawer-body .observer-panel .ant4-pagination,
.tasks-observer-popup .ant4-drawer-body .observer-panel .ant4-pagination,
.tasks-observer-drawer .ant4-popover-inner-content .observer-panel .ant4-pagination,
.tasks-observer-popup .ant4-popover-inner-content .observer-panel .ant4-pagination {
  margin: 12px;
  width: 100%;
}
.task-observer-sider-panel .title {
  font-size: 22px;
}
@media screen and (max-height: 1000px) {
  .tasks-observer-popup .ant4-popover-inner-content .observer-panel {
    max-height: 650px;
  }
}
@media screen and (max-height: 800px) {
  .tasks-observer-popup .ant4-popover-inner-content .observer-panel {
    max-height: 450px;
  }
}
@media screen and (max-height: 600px) {
  .tasks-observer-popup .ant4-popover-inner-content .observer-panel {
    max-height: 250px;
  }
}
