.tasks-observer-drawer .tasks-panel .action-bar,
.tasks-observer-popup .tasks-panel .action-bar {
  margin: 0 10px 5px 10px;
  padding: 10px 15px 0px 15px;
  flex-shrink: 0;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text,
.tasks-observer-popup .tasks-panel .task-item .status-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text > .anticon,
.tasks-observer-popup .tasks-panel .task-item .status-text > .anticon {
  margin-top: 5px;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text .info,
.tasks-observer-popup .tasks-panel .task-item .status-text .info {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 0;
  margin-left: 10px;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text .info .name-row,
.tasks-observer-popup .tasks-panel .task-item .status-text .info .name-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text .info .name-row .name,
.tasks-observer-popup .tasks-panel .task-item .status-text .info .name-row .name {
  width: 0;
  flex: 1 1;
  font-size: 15px;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text .info .name-row .ant4-tag,
.tasks-observer-popup .tasks-panel .task-item .status-text .info .name-row .ant4-tag {
  margin-right: 0;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text .info .name-row .mgl5,
.tasks-observer-popup .tasks-panel .task-item .status-text .info .name-row .mgl5 {
  margin-left: 5px !important;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text .info .name-row .stop,
.tasks-observer-popup .tasks-panel .task-item .status-text .info .name-row .stop {
  padding: 2px 3px 0px 3px;
  height: 22px;
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text .info .description,
.tasks-observer-popup .tasks-panel .task-item .status-text .info .description {
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 3px;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text .time,
.tasks-observer-popup .tasks-panel .task-item .status-text .time {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text .time .key,
.tasks-observer-popup .tasks-panel .task-item .status-text .time .key {
  opacity: 0.7;
  font-size: 13px;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text .time .value,
.tasks-observer-popup .tasks-panel .task-item .status-text .time .value {
  margin-left: 3px;
  opacity: 0.7;
  font-size: 13px;
}
.tasks-observer-drawer .tasks-panel .task-item .status-text .time .duration,
.tasks-observer-popup .tasks-panel .task-item .status-text .time .duration {
  flex: 1 1;
}
.tasks-observer-drawer .tasks-panel .task-item .ant4-progress.has-warn .anticon-check-circle,
.tasks-observer-popup .tasks-panel .task-item .ant4-progress.has-warn .anticon-check-circle {
  color: var(--ant4-warning-color);
}
