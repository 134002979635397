.freedesign.list-grid-v4 {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.freedesign.list-grid-v4 > .list-grid-v4-item {
  height: auto;
  width: auto;
  margin: 0 10px 10px 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: unset;
  text-align: left;
}
.freedesign.list-grid-v4 > .list-grid-v4-item .list-grid-v4-item--list-item {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
}
.freedesign.list-grid-v4 > .list-grid-v4-item .list-grid-v4-item--gallery-item {
  width: auto;
  height: auto;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
}
.freedesign.list-grid-v4.list-grid-v4-v {
  display: flex;
  flex-direction: column;
}
.freedesign.list-grid-v4.list-grid-v4-v > .list-grid-v4-item {
  width: 100%;
}
.freedesign.list-grid-v4.list-grid-v4-h {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.freedesign.list-grid-v4.list-grid-v4-h > .list-grid-v4-item {
  margin: 10px 10px 10px 10px;
}
.freedesign.list-grid-v4.grafana .panel {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #f4f5f5;
}
.freedesign.list-grid-v4.grafana .panel.hoverable:hover {
  border: 1px solid var(--ant4-primary-4);
  background-color: white;
}
.freedesign.list-grid-v4.grafana .panel.hoverable:active {
  border: 1px solid var(--ant4-primary-4);
  background-color: var(--ant4-primary-2);
}
.freedesign.list-grid-v4.grafana.white .panel {
  background-color: white;
}
.freedesign.list-grid-v4.grafana.white .panel.hoverable:hover {
  border: 1px solid var(--ant4-primary-4);
  background-color: var(--ant4-primary-1);
}
.freedesign.list-grid-v4.grafana.white .panel.hoverable:active {
  border: 1px solid var(--ant4-primary-4);
  background-color: var(--ant4-primary-2);
}
.freedesign.list-grid-v4.grafana.white.alpha50 .panel {
  background-color: rgba(255, 255, 255, 0.5);
}
.freedesign.list-grid-v4.grafana.white.alpha50 .panel.hoverable:hover {
  border: 1px solid var(--ant4-primary-4);
  background-color: var(--ant4-primary-1);
}
.freedesign.list-grid-v4.grafana.white.alpha50 .panel.hoverable:active {
  border: 1px solid var(--ant4-primary-4);
  background-color: var(--ant4-primary-2);
}
.freedesign.list-grid-v4.grafana.white.alpha30 .panel {
  background-color: rgba(255, 255, 255, 0.3);
}
.freedesign.list-grid-v4.grafana.white.alpha30 .panel.hoverable:hover {
  border: 1px solid var(--ant4-primary-4);
  background-color: var(--ant4-primary-1);
}
.freedesign.list-grid-v4.grafana.white.alpha30 .panel.hoverable:active {
  border: 1px solid var(--ant4-primary-4);
  background-color: var(--ant4-primary-2);
}
.freedesign.list-grid-v4.liststyle .panel {
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-bottom: 1px solid rgba(36, 41, 46, 0.12);
  border-radius: 4px;
  background-color: transparent;
}
.freedesign.list-grid-v4.liststyle button:last-child .panel {
  border-bottom: 1px solid transparent;
}
.freedesign.list-grid-v4.liststyle .panel.hoverable:hover {
  border: 1px solid var(--ant4-primary-4);
  background-color: white;
}
.freedesign.list-grid-v4.liststyle .panel.hoverable:active {
  border: 1px solid var(--ant4-primary-4);
  background-color: var(--ant4-primary-1);
}
.freedesign.list-grid-v4.liststyle .item-selected .panel.hoverable:hover {
  background-color: var(--ant4-primary-1);
  border: 1px solid var(--ant4-primary-4);
}
.freedesign.list-grid-v4.liststyle .item-selected .panel.hoverable:active {
  background-color: var(--ant4-primary-1);
  border: 1px solid var(--ant4-primary-4);
}
.freedesign.list-grid-v4.liststyle .item-selected .panel.hoverable {
  background-color: var(--ant4-primary-1);
  border: 1px solid var(--ant4-primary-4);
}
