.freesia-design.sample-page {
    width         : 100%;
    height        : 100%;
    display       : flex;
    flex-direction: column;

    >.tools {
        // background-color: white;
        padding      : 15px 10px 15px 24px;
        border-bottom: 1px solid #e8e8e8;
        z-index      : 1;

        .ant4-radio-button-wrapper {
            padding: 0 0px;
            width  : 32px;

            >span:last-child {
                width          : 100%;
                height         : 100%;
                display        : flex;
                justify-content: center;
                align-items    : center;

                .anticon {
                    font-size: 18px;
                }
            }

        }
    }

    >.content {
        overflow-y: auto;

        .selector-files {
            height: 40px;

            .ant4-select-selector {
                height: 40px;
            }
        }

        .ant4-typography {
            pre {
                margin       : 16px 0;
                padding      : 12px 20px;
                border       : 0px;
                border-radius: 0;
                background   : rgba(197, 197, 197, .1);
            }
        }
    }

}


@media screen and (max-width: 500px) {
    .freesia-design.sample-page {

        >.tools {
            padding: 15px 10px 15px 54px;
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;