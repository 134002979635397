.layout-classic-content {
  position: relative;
  height: 100%;
}
.layout-classic-content .sider-drawer-switcher {
  position: absolute;
  left: 0;
  top: 10px;
  z-index: 5;
  box-shadow: 2px 0 8px #00000026;
  border-radius: 0 4px 4px 0;
  border: 0;
  width: 41px;
  height: 40px;
  padding: 0;
}
