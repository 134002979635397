@import '@yulintu/freesia-design/css/variables.less';

.property-table {

    tr {
        td {
            // border-bottom: 0 !important;
        }

        td:first-child {
            border-right: 3px solid rgba(0, 0, 0, 0.05) !important;
        }
    }

    tr.row-al {
        background-color: rgba(129, 129, 129, 0.1);
    }

    tr:not(row-al) {
        background-color: rgba(76, 76, 76, 0.02);
    }

    td {
        border-bottom: 0;
        padding      : 10px 12px !important;

        .key {
            font-size: 15px !important;
            color    : black;
        }

        .value {
            font-size: 15px;
            color    : rgba(0, 0, 0, .7);
        }
    }

}
@ant-prefix: ant4;@root-entry-name: variable;