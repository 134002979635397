.freesia-design {
  background-color: transparent;
}
html {
  --ant4-success-color: #64A319 !important;
  --ant4-success-color-hover: #7fcf20 !important;
  --ant4-success-color-active: #497712 !important;
  --ant4-error-color: #c5474a !important;
  --ant4-error-color-hover: #d16e70 !important;
  --ant4-error-color-active: #a53437 !important;
  --ant4-warning-color: #f58800 !important;
  --ant4-warning-color-hover: #ffa029 !important;
  --ant4-warning-color-active: #c26c00 !important;
}
