.fullscreen-dialog-wrap {

    background-color: #f0f2f5;

    // display: flex;
    // flex-direction: column;
    // overflow: hidden;
    .ant4-modal {
        height        : 100%;
        padding-bottom: 0;

        //     top: 0px;
        //     padding-bottom: 0px;
        //     flex: 1 1;
        //     width: 100% !important;
        //     max-width: unset !important;
        //     min-width: 200px;
        //     margin: 0;
        //     display: flex;
        //     flex-direction: column;
        //     overflow: hidden;
        .ant4-modal-content {
            box-shadow      : unset;
            height          : 100%;
            background-color: #f0f2f5 !important;

            flex          : 1 1;
            display       : flex;
            flex-direction: column;

            margin-left : 24px;
            margin-right: 24px;

            .ant4-modal-close {
                top         : 23px;
                margin-right: 10px;

                .ant4-modal-close-x {
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    border-radius   : 100%;
                    background-color: rgba(0, 0, 0, .04);

                    .anticon-close {
                        font-size: 24px;
                    }
                }

                &:hover {
                    .ant4-modal-close-x {
                        background-color: rgba(0, 0, 0, .07);
                    }
                }

                &:active {
                    .ant4-modal-close-x {
                        background-color: rgba(0, 0, 0, .1);
                    }
                }
            }

            .ant4-modal-header {
                >.ant4-modal-title {

                    margin-right: 70px;
                    border-right: 1px solid #eaeaea;

                    >.fullscreen-modal-title {
                        margin   : 10px 30px 10px 0;
                        font-size: 22px;

                        display       : flex;
                        flex-direction: row;
                        align-items   : center;

                        >.anticon {
                            font-size   : 50px;
                            margin-right: 15px;
                        }

                        >.title {
                            font-size: 22px;
                        }

                        >.title-second {
                            font-size: 16px;
                        }

                        >button {
                            height   : 37px;
                            min-width: 80px;
                        }
                    }
                }

            }

            .ant4-modal-footer {
                padding: 0px 16px 20px;
            }

            .ant4-modal-body {
                flex            : 1 1;
                // overflow-y   : auto;
                padding         : 0;
                margin-bottom   : 24px;
                height          : 0;

                .ant4-tabs-top.gradient-ink-bar {
                    overflow: unset;

                    .ant4-tabs-content-top {
                        overflow: unset;
                    }
                }

            }
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;