.ant4-typography.anchor-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant4-typography.anchor-title a.anchor {
  margin-left: 8px;
  opacity: 0;
  transition: opacity 0.3s;
  font-weight: 600;
  font-size: inherit;
}
.ant4-typography.anchor-title:hover a.anchor {
  opacity: 1;
}
