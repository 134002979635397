.layout-micro-app-frame {
  width: 100%;
  height: 100%;
}
.layout-micro-app-frame .layout-micro-app {
  width: 100%;
  height: 100%;
}
.layout-micro-app-frame .layout-micro-app > div {
  height: 100%;
}
