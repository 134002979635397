@import '@yulintu/freesia-design/css/variables.less';

.modal--task-job-detail {

    .ant4-modal-header {
        padding: 24px 24px 10px 10px;
    }

    .ant4-tabs-tab-btn {
        font-size: 16px;
    }

    .task-job-title {
        display       : flex;
        flex-direction: row;
        align-items   : center;

        >.anticon {
            font-size: 64px;
        }

        >.name-col {
            display       : flex;
            flex-direction: column;
            margin-left   : 20px;

            .name {
                font-size   : 22px;
                margin-right: 13px;
            }

            .ant4-progress {
                // max-width   : 280px;
                width       : 100%;
                margin-right: 10px;
                margin-top  : 8px;
            }

            .ant4-progress.has-warn {
                .anticon-check-circle {
                    color:~'var(--@{ant-prefix}-warning-color)';
                }
            }
        }

        .info {
            width         : 0;
            flex          : 1 1;
            display       : flex;
            flex-direction: column;
            margin-left   : 10px;


            >.name-row {
                display       : flex;
                flex-direction: row;
                align-items   : center;

                .ant4-tag {
                    font-size     : 14px;
                    padding       : 2px 7px;
                    display       : flex;
                    flex-direction: row;
                    align-items   : center;
                    margin-top    : 0px;
                }
            }

            .time {
                display       : flex;
                flex-direction: row;
                align-items   : center;
                margin-left   : 5px;
                margin-top    : 6px;

                .key {
                    opacity  : .7;
                    font-size: 14px;
                }

                .value {
                    margin-left: 3px;
                    opacity    : .7;
                    font-size  : 14px;
                }

                .duration {
                    flex: 1 1;
                }


            }
        }


        .stop {
            height      : 38px;
            width       : 80px;
            margin-right: 30px;
        }
    }

    .card-settings {
        padding: 25px 35px !important;

        .log {
            display       : flex;
            flex-direction: row;
            align-items   : center;

            padding: 5px 10px;

            .anticon {
                font-size   : 16px;
                margin-right: 8px;
            }

            &.row-al {
                background-color: rgb(245, 245, 245);
            }

            &.error,
            &.exception {
                color:~'var(--@{ant-prefix}-error-color)';
            }

            >span {
                white-space: pre-wrap;
            }
        }

        &.logs {
            padding: 35px 35px !important;
        }

        &.results {
            padding: 35px 35px !important;
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;