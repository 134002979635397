.freedesign.nodata {
    height: 100%;
    width : 100%;

    justify-content: center;

    >.title {
        margin-top: 8px;
        font-size : 15px;
    }
}
@ant-prefix: ant4;@root-entry-name: variable;