.property-table tr td:first-child {
  border-right: 3px solid rgba(0, 0, 0, 0.05) !important;
}
.property-table tr.row-al {
  background-color: rgba(129, 129, 129, 0.1);
}
.property-table tr:not(row-al) {
  background-color: rgba(76, 76, 76, 0.02);
}
.property-table td {
  border-bottom: 0;
  padding: 10px 12px !important;
}
.property-table td .key {
  font-size: 15px !important;
  color: black;
}
.property-table td .value {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
}
