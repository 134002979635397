.ant4-typography.anchor-title {
    display       : flex;
    flex-direction: row;
    align-items   : center;

    a.anchor {
        margin-left: 8px;
        opacity    : 0;
        transition : opacity .3s;
        font-weight: 600;
        font-size  : inherit;

    }

    &:hover {
        a.anchor {
            opacity: 1;
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;