.unread-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e76162;
  color: white;
  padding: 2px 10px 4px 10px;
  font-size: 20px;
  line-height: 20px;
  border-radius: 15px;
  font-weight: 500;
  margin-top: 2px;
}
.primary-header-button.message-observer {
  min-width: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-observer-drawer .ant4-drawer-body {
  padding: 0;
  overflow: hidden;
}
.message-observer-drawer .ant4-drawer-body .observer-panel {
  padding: 5px 0 0 0;
  margin: 0px 0;
  height: 100%;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .header {
  padding: 10px 20px;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .header .title {
  font-size: 22px;
  margin-right: 10px;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .header .unread-title {
  font-size: 18px;
  line-height: 18px;
  margin-top: 2px;
  padding-bottom: 3px;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .observer-item {
  margin: 0px 15px;
  padding: 15px 0 12px 0;
  border-bottom: 1px solid #eee;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .observer-item .ant4-progress .anticon {
  font-size: 18px;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .anticon {
  color: #c8c8c8;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .info {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 0;
  margin-left: 5px;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .info .name-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .info .name-row .state {
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: var(--ant4-info-color);
  margin-right: 7px;
  margin-left: 10px;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .info .name-row .state.error {
  background-color: var(--ant4-error-color);
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .info .name-row .state.success {
  background-color: var(--ant4-success-color);
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .info .name-row .state.warning {
  background-color: var(--ant4-warning-color);
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .info .name-row .name {
  width: 0;
  flex: 1 1;
  font-size: 15px;
  font-weight: bold;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .info .description {
  opacity: 1;
  font-size: 13px;
  margin-bottom: 3px;
  margin-top: 3px;
  color: black;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .time {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .time .key {
  opacity: 0.7;
  font-size: 13px;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .time .value {
  margin-left: 3px;
  opacity: 0.7;
  font-size: 13px;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .message-item .status-text .time .duration {
  flex: 1 1;
}
.message-observer-drawer .ant4-drawer-body .observer-panel .ant4-pagination {
  margin: 12px;
  width: 100%;
}
