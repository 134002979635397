.freedesign.layout-draggable-grid {
  width: 100%;
  height: 100%;
}
.freedesign.layout-draggable-grid > .layout-grid-children {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.freedesign.layout-draggable-grid > .layout-grid-children > * {
  pointer-events: auto;
}
.freedesign.layout-draggable-grid > .ant4-breadcrumb {
  margin-left: 24px;
}
.freedesign.layout-draggable-grid .react-grid-item.card {
  background-color: white;
}
.freedesign.layout-draggable-grid .react-grid-item.border-card {
  background-color: white;
  border: 1px solid rgba(5, 5, 5, 0.06);
}
.freedesign.layout-draggable-grid .react-grid-item.round-border-card {
  background-color: white;
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 8px;
}
.freedesign.layout-draggable-grid .react-grid-item.shadow-card {
  background-color: white;
  box-shadow: 0 1px 4px #00000012;
}
