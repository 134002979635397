.backstage-modal.app-gallery-modal {
  width: 1188px !important;
  max-width: unset !important;
}
.backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-header {
  padding-top: 22px;
}
.backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-header .ant4-modal-title {
  font-size: 22px;
  color: #555;
}
.backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-header .ant4-modal-title .action-bar {
  height: 46px;
  padding: 3px 0 3px 15px;
}
.backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-body {
  position: relative;
  overflow: hidden;
  padding-right: 0px;
}
.backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-body .background-svg {
  opacity: 0.3;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
}
.backstage-modal.app-gallery-modal .app-list-frame {
  height: 530px;
  padding: 0px 50px 20px 50px;
  overflow: hidden;
  overflow-y: auto !important;
}
.backstage-modal.app-gallery-modal .app-list-frame .app-gallery-title {
  padding: 0 0 10px 30px;
  margin-top: 10px;
}
.backstage-modal.app-gallery-modal .app-list-frame .app-gallery-title .title-text {
  font-size: 22px;
}
.backstage-modal.app-gallery-modal .app-list-frame .app-gallery-title:not(:first-child) {
  margin-top: 30px;
}
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery {
  align-items: flex-start;
  align-content: flex-start;
}
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item {
  border-color: transparent;
}
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item {
  padding: 10px 15px;
  width: 480px;
  height: 96px;
}
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item .logo {
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item .logo .anticon {
  font-size: 56px;
}
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item .content {
  margin-left: 15px;
  overflow: hidden;
}
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item .content .title {
  font-size: 22px;
  color: black;
}
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item .content .title:hover,
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item .content .title:active {
  color: inherit;
}
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item .content .description {
  margin-bottom: 0;
  color: gray;
  margin-top: 0px;
  font-size: 13px;
}
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item:hover .content .title,
.backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item:active .content .title {
  color: inherit;
}
.fullscreen-dialog-wrap .backstage-modal.app-gallery-modal {
  width: 100% !important;
}
.fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .ant4-modal-content {
  margin-left: 0;
  margin-right: 0;
  background-color: white !important;
}
.fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-header .ant4-modal-title {
  font-size: 26px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 35px;
}
.fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-header .ant4-modal-title .action-bar {
  height: 46px;
  padding: 3px 0;
}
.fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-close {
  margin-right: 20px;
  margin-top: 12px;
}
.fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-body {
  margin-bottom: 0;
}
.fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-body .app-list-frame {
  height: 100%;
}
@media screen and (max-width: 1250px) {
  .backstage-modal.app-gallery-modal {
    width: 1024px !important;
  }
  .backstage-modal.app-gallery-modal .app-list-frame {
    padding: 0px 0px 20px 0px;
  }
  .backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item {
    width: 950px;
    height: 96px;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal {
    width: 100% !important;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .app-list-frame {
    padding: 0px 30px 30px 30px;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item {
    width: 100%;
  }
}
@media screen and (max-width: 1100px) {
  .backstage-modal.app-gallery-modal {
    width: 700px !important;
  }
  .backstage-modal.app-gallery-modal .app-list-frame {
    padding: 0px 0px 20px 0px;
  }
  .backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item {
    width: 620px;
    height: 96px;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal {
    width: 100% !important;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .app-list-frame {
    padding: 0px 30px 30px 30px;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item {
    width: 100%;
  }
}
@media screen and (max-height: 768px) {
  .backstage-modal.app-gallery-modal .app-list-frame {
    height: 400px;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .app-list-frame {
    height: 100%;
  }
}
@media screen and (max-height: 650px) {
  .backstage-modal.app-gallery-modal .app-list-frame {
    height: 300px;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .app-list-frame {
    height: 100%;
  }
}
@media screen and (max-height: 550px) {
  .backstage-modal.app-gallery-modal .app-list-frame {
    height: 200px;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .app-list-frame {
    height: 100%;
  }
}
@media screen and (max-width: 768px) {
  .backstage-modal.app-gallery-modal {
    width: 100% !important;
    height: 100%;
    top: 0;
    margin: 0;
    padding: 0;
  }
  .backstage-modal.app-gallery-modal .ant4-modal-content {
    height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }
  .backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-body {
    height: 0;
    flex: 1 1;
    padding: 10px 0 10px 10px;
  }
  .backstage-modal.app-gallery-modal .app-list-frame {
    padding: 0px 0px 20px 0px;
    height: 100%;
  }
  .backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    padding-right: 30px;
  }
  .backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item {
    width: 100%;
  }
  .backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item {
    width: 100%;
    height: 96px;
  }
  .backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item .content .title {
    font-size: 18px;
  }
  .backstage-modal.app-gallery-modal .app-list-frame .list-grid--app-gallery .list-grid-v4-item--gallery-item .app-gallery-item--gallery-item .content .description {
    margin-bottom: 0.5em !important;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-header .ant4-modal-title {
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .ant4-modal-content .ant4-modal-close {
    margin-top: 2px;
    margin-right: 18px;
  }
  .fullscreen-dialog-wrap .backstage-modal.app-gallery-modal .ant4-modal-content .app-list-frame {
    padding: 0px 0px 20px 0px;
  }
}
