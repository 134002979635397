.modal--task-job-detail .ant4-modal-header {
  padding: 24px 24px 10px 10px;
}
.modal--task-job-detail .ant4-tabs-tab-btn {
  font-size: 16px;
}
.modal--task-job-detail .task-job-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.modal--task-job-detail .task-job-title > .anticon {
  font-size: 64px;
}
.modal--task-job-detail .task-job-title > .name-col {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.modal--task-job-detail .task-job-title > .name-col .name {
  font-size: 22px;
  margin-right: 13px;
}
.modal--task-job-detail .task-job-title > .name-col .ant4-progress {
  width: 100%;
  margin-right: 10px;
  margin-top: 8px;
}
.modal--task-job-detail .task-job-title > .name-col .ant4-progress.has-warn .anticon-check-circle {
  color: var(--ant4-warning-color);
}
.modal--task-job-detail .task-job-title .info {
  width: 0;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.modal--task-job-detail .task-job-title .info > .name-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.modal--task-job-detail .task-job-title .info > .name-row .ant4-tag {
  font-size: 14px;
  padding: 2px 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
}
.modal--task-job-detail .task-job-title .info .time {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  margin-top: 6px;
}
.modal--task-job-detail .task-job-title .info .time .key {
  opacity: 0.7;
  font-size: 14px;
}
.modal--task-job-detail .task-job-title .info .time .value {
  margin-left: 3px;
  opacity: 0.7;
  font-size: 14px;
}
.modal--task-job-detail .task-job-title .info .time .duration {
  flex: 1 1;
}
.modal--task-job-detail .task-job-title .stop {
  height: 38px;
  width: 80px;
  margin-right: 30px;
}
.modal--task-job-detail .card-settings {
  padding: 25px 35px !important;
}
.modal--task-job-detail .card-settings .log {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
}
.modal--task-job-detail .card-settings .log .anticon {
  font-size: 16px;
  margin-right: 8px;
}
.modal--task-job-detail .card-settings .log.row-al {
  background-color: #f5f5f5;
}
.modal--task-job-detail .card-settings .log.error,
.modal--task-job-detail .card-settings .log.exception {
  color: var(--ant4-error-color);
}
.modal--task-job-detail .card-settings .log > span {
  white-space: pre-wrap;
}
.modal--task-job-detail .card-settings.logs {
  padding: 35px 35px !important;
}
.modal--task-job-detail .card-settings.results {
  padding: 35px 35px !important;
}
