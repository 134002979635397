.primary-header-button.tasks-observer {
    min-width      : 58px;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.tasks-observer-drawer,
.tasks-observer-popup {

    .ant4-drawer-body,
    .ant4-popover-inner-content {
        padding: 0;

        .tabs {
            overflow    : hidden;
            // min-width: 320px;

            .ant4-tabs-nav {
                margin-bottom: 0;

                .ant4-tabs-nav-list {
                    margin-left: 0px;
                }
            }

            .ant4-tabs-content-top {
                overflow-x: hidden;
            }
        }

        .observer-panel {
            padding      : 5px 0 0 0;
            margin       : 0px 0;
            height       : 100%;
            // min-height: 300px;
            // max-height: 700px;

            .observer-item {
                margin       : 0px 20px;
                padding      : 15px 0 12px 0;
                border-bottom: 1px solid #eee;


                .ant4-progress {
                    .anticon {
                        font-size: 18px;
                    }
                }
            }

            .ant4-pagination {
                margin: 12px;
                width : 100%;
            }

            .nodata {
                // margin-top: 80px;
            }
        }
    }
}

.task-observer-sider-panel {
    .title {
        font-size: 22px;
    }
}


@media screen and (max-height: 1000px) {
    .tasks-observer-popup {
        .ant4-popover-inner-content {

            .observer-panel {
                max-height: 650px;
            }
        }
    }
}


@media screen and (max-height: 800px) {
    .tasks-observer-popup {
        .ant4-popover-inner-content {

            .observer-panel {
                max-height: 450px;
            }
        }
    }
}

@media screen and (max-height: 600px) {
    .tasks-observer-popup {
        .ant4-popover-inner-content {

            .observer-panel {
                max-height: 250px;
            }
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;