.newpropertygrid-container {
  position: relative;
}
.newpropertygrid-container .propertygrid .propertygrid-sheet {
  background: white;
  border: 1px solid #e2e2e2;
  padding: 12px 15px 15px 15px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  box-shadow: 0 0 3px #efefef;
}
.newpropertygrid-container .propertygrid .propertygrid-sheet .title {
  display: flex;
  margin: 0 !important;
  font-size: 15px !important;
  font-weight: bold;
  margin-bottom: 15px !important;
}
.newpropertygrid-container .propertygrid .propertygrid-sheet .property-item-dirty-name > span {
  text-decoration: line-through;
}
.newpropertygrid-container .propertygrid .propertygrid-sheet .ant4-pro-checkcard .ant4-pro-checkcard-avatar {
  padding-right: 15px;
}
.newpropertygrid-container .propertygrid .propertygrid-sheet .ant4-pro-checkcard .title-card {
  font-size: 15px;
  font-weight: bold;
}
.newpropertygrid-container.orient-v .propertygrid .propertygrid-sheet .title {
  font-size: 18px !important;
  font-weight: normal;
  color: black;
  margin-left: 0px !important;
}
.newpropertygrid-container.orient-v .ant-form-item .ant-form-item-label {
  line-height: unset;
  padding-left: 1px;
}
.newpropertygrid-container.orient-v .ant-form-item .ant-form-item-label .description {
  font-size: 12px;
}
.newpropertygrid-container.orient-v .ant-form-item:not(:last-child) {
  margin-bottom: 16px;
}
.newpropertygrid-container.form .propertygrid .propertygrid-sheet {
  border: 0;
  box-shadow: unset;
}
.newpropertygrid-container.form .propertygrid .propertygrid-sheet .title {
  font-size: 22px !important;
  font-weight: 400;
  color: #000;
  margin-left: 0 !important;
  margin-bottom: 25px !important;
}
.newpropertygrid-container.form .propertygrid .propertygrid-sheet .body {
  margin-left: 2px;
}
.newpropertygrid-container.form .ant4-form-item {
  margin-bottom: 5px !important;
}
.newpropertygrid-container.form .ant4-form-item .ant4-form-item-label {
  margin-right: 5px;
}
.newpropertygrid-container.form .ant4-form-item .ant4-form-item-label .name {
  color: black;
}
.newpropertygrid-container.form .ant4-form-item .ant4-form-item-control .ant4-form-item-explain {
  position: absolute;
  left: calc(100% + 8px);
  top: 5px;
  white-space: nowrap;
}
.newpropertygrid-container.form.orient-v .ant4-form-item {
  margin-bottom: 15px !important;
}
.newpropertygrid-container.form.orient-v .ant4-form-item .ant4-form-item-label {
  padding-bottom: 4px;
}
.newpropertygrid-container.kv .propertygrid .propertygrid-sheet {
  border: 0;
  box-shadow: unset;
  border-radius: 8px;
  padding: 20px;
}
.newpropertygrid-container.kv .propertygrid .propertygrid-sheet .title {
  color: rgba(0, 0, 0, 0.65);
}
.newpropertygrid-container.kv .ant4-form-item .ant4-form-item-label .anticon {
  font-size: 22px;
}
.newpropertygrid-container.kv .ant4-form-item .ant4-form-item-label .name {
  margin-left: 5px;
}
.newpropertygrid-container.kv.compact .ant4-form-item {
  margin-bottom: 8px;
}
