.freedesign.layout-one-row-grid {
    width        : 100%;
    height       : 100%;
    padding-left : 24px;
    padding-right: 24px;

    >.layout-grid-children {
        pointer-events: none;
        position      : absolute;
        left          : 0;
        top           : 0;
        right         : 0;
        bottom        : 0;
    }

    >.ant4-breadcrumb {
        // margin-left: 24px;
    }

    .grid-item {

        // border-color: ;

        &.none {
        }

        &.card {
            background-color: white;
        }

        &.border-card {
            background-color: white;
            border          : 1px solid rgba(5, 5, 5, 0.06);
        }

        &.round-border-card {
            background-color: white;
            border          : 1px solid rgba(5, 5, 5, 0.06);
            border-radius   : 8px;
        }

        &.shadow-card {
            background-color: white;
            box-shadow      : 0 1px 4px #00000012;
        }

    }
}
@ant-prefix: ant4;@root-entry-name: variable;