.layout-classic {
  display: flex;
  height: 100%;
  flex-direction: column !important;
}
.layout-classic .ant4-layout-header.layout-classic-header {
  height: 60px;
  padding: 0;
  line-height: 60px;
  z-index: 1;
}
.layout-classic .ant4-layout-header.layout-classic-header > .header-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.layout-classic .ant4-layout-header.layout-classic-header > .header-content button.app-gallery {
  flex-shrink: 0;
  width: 60px;
  height: 100%;
  justify-content: center;
}
.layout-classic .ant4-layout-header.layout-classic-header > .header-content button.app-gallery .anticon {
  color: white;
}
.layout-classic .ant4-layout-header.layout-classic-header > .header-content .app-title {
  padding: 4px 15px;
  flex-shrink: 0;
  height: 100%;
}
.layout-classic .ant4-layout-header.layout-classic-header > .header-content .app-title .logo {
  max-height: 34px;
  min-width: 34px;
  min-height: 34px;
}
.layout-classic .ant4-layout-header.layout-classic-header > .header-content .app-title .anticon {
  font-size: 34px;
  color: white;
}
.layout-classic .ant4-layout-header.layout-classic-header > .header-content .app-title .title {
  font-size: 22px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
}
.layout-classic .ant4-layout-header.layout-classic-header > .header-content .app-title .title .subtitle {
  opacity: 0.8;
  font-weight: 500;
  margin-left: 10px;
  font-size: 16px;
}
.layout-classic .ant4-layout-header.layout-classic-header > .header-content .app-title .title .subtitle .subtitle-extra {
  margin-left: 5px;
}
.layout-classic .ant4-layout-header.layout-classic-header > .header-content .app-title .title .subtitle .subtitle-extra .anticon {
  font-size: 16px;
}
.layout-classic .ant4-layout-header.layout-classic-header > .header-content .addons {
  padding-right: 0px;
}
.layout-classic .ant4-layout-header.layout-classic-header .primary-header-dropdown {
  color: rgba(255, 255, 255, 0.65);
  padding: 0 15px;
}
.layout-classic .ant4-layout-header.layout-classic-header .primary-header-dropdown:hover {
  color: white;
}
.layout-classic .ant4-layout-header.layout-classic-header .primary-header-dropdown.ant4-dropdown-open {
  color: white;
}
.layout-classic .ant4-layout-header.layout-classic-header .primary-header-button {
  height: 100%;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-width: 0;
}
.layout-classic .ant4-layout-header.layout-classic-header .drawer-swticher {
  width: 50px;
}
.layout-classic .ant4-layout-header.layout-classic-header.dark {
  background: #3d4146;
}
.layout-classic .ant4-layout-header.layout-classic-header.light {
  background: white;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content button.app-gallery .anticon {
  color: inherit;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .app-title .anticon {
  color: inherit;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .app-title .title {
  color: inherit;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .primary-header-dropdown {
  color: #000000d9;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-item {
  height: 60px;
  border-bottom-width: 3px;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-item::after {
  border-bottom-width: 3px;
  right: 0;
  left: 0;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-item:hover {
  border-bottom-width: 3px;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-item:hover::after {
  border-bottom-width: 3px;
  right: 0;
  left: 0;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-item-selected {
  border-bottom-width: 3px;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-item-selected::after {
  border-bottom-width: 3px;
  right: 0;
  left: 0;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-submenu-selected {
  border-bottom-width: 3px;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-submenu-selected::after {
  border-bottom-width: 3px;
  right: 0;
  left: 0;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-submenu-horizontal {
  height: 60px;
  border-bottom-width: 3px;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-submenu-horizontal::after {
  border-bottom-width: 3px;
  right: 0;
  left: 0;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-submenu-horizontal:hover {
  border-bottom-width: 3px;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-submenu-horizontal:hover::after {
  border-bottom-width: 3px;
  right: 0;
  left: 0;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-submenu-active {
  border-bottom-width: 3px;
}
.layout-classic .ant4-layout-header.layout-classic-header.light > .header-content .header-menu.light .ant4-menu-submenu-active::after {
  border-bottom-width: 3px;
  right: 0;
  left: 0;
}
.layout-classic .ant4-layout-header.layout-classic-header.shadow {
  z-index: 2;
  box-shadow: 0 2px 4px #00000025;
}
.layout-classic .ant4-layout-header.layout-classic-header.shadow.light {
  z-index: 2;
  box-shadow: 0 2px 8px #0000000e;
}
.layout-classic .layout-classic-content-frame {
  position: relative;
  width: 100% !important;
}
.layout-classic .layout-classic-content-frame.shadow {
  box-shadow: 0 -2px 4px #00000025;
  z-index: 2;
}
.layout-classic .layout-classic-content {
  position: relative;
  height: 100%;
}
.layout-classic .layout-classic-content .sider-drawer-switcher {
  position: absolute;
  left: 0;
  top: 10px;
  z-index: 5;
  box-shadow: 2px 0 8px #00000026;
  border-radius: 0 4px 4px 0;
  border: 0;
  width: 41px;
  height: 40px;
  padding: 0;
}
.layout-classic .layout-classic-content .layout-classic-content-sider {
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 8px 0 #1d23290d;
  z-index: 1;
  transition: width 0.2s, flex 0.2s, max-width 0.2s, min-width 0.2s;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-layout-sider-trigger {
  background: transparent;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .sider-title {
  font-size: 22px;
  padding: 15px 20px 10px 30px;
  margin-bottom: 5px;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
.layout-classic .layout-classic-content .layout-classic-content-sider.ant4-layout-sider-collapsed .sider-title-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.layout-classic .layout-classic-content .layout-classic-content-sider.ant4-layout-sider-collapsed .sider-title {
  visibility: hidden;
  padding: 15px 0px 10px 0px;
  width: 22px;
}
.layout-classic .layout-classic-content .layout-classic-content-sider.ant4-layout-sider-collapsed .sider-title:first-letter {
  visibility: visible !important;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .anticon {
  transition: font-size 0.3s;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-layout-sider-children {
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-layout-sider-children::-webkit-scrollbar {
  width: 0;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-layout-sider-children:hover::-webkit-scrollbar {
  width: 12px;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu.ant4-menu-inline {
  border-right-color: transparent;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-sub .ant4-menu-item > .ant4-menu-title-content {
  margin-left: 12px;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-sub .ant4-menu-submenu > .ant4-menu-submenu-title > .ant4-menu-title-content {
  margin-left: 12px;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-item {
  margin-bottom: 2px;
  margin-top: 2px;
  line-height: 44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-item .anticon {
  font-size: 26px;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-submenu-title {
  margin-bottom: 2px;
  margin-top: 2px;
  line-height: 44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-submenu-title .anticon {
  font-size: 26px;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-item-group {
  margin-bottom: 10px;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-item-group .ant4-menu-item-group-title {
  padding-left: 24px;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-sub.ant4-menu-inline {
  background: transparent;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu-inline-collapsed {
  width: calc(100% + 1px);
  border-right-color: transparent;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-item {
  margin-bottom: 2px;
  margin-top: 2px;
  line-height: 44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  padding-left: 0 !important;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-item .ant4-menu-item-icon {
  margin-top: 4px !important;
  font-size: 28px !important;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-item .ant4-menu-title-content {
  width: 0;
  margin-left: 0;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-item-group {
  margin-bottom: 10px;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-item-group .ant4-menu-item-group-title {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-submenu-title {
  margin-bottom: 2px;
  margin-top: 2px;
  line-height: 44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  padding-left: 0 !important;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-submenu-title .anticon {
  margin-top: 6px !important;
  font-size: 28px !important;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-submenu-title .ant4-menu-title-content {
  width: 0;
  margin-left: 0;
}
.layout-classic .layout-classic-content.white {
  background: white;
}
.layout-classic .layout-classic-content.white .layout-classic-content-sider {
  box-shadow: unset;
  border-right: 1px solid #f1f1f1;
}
.layout-classic .layout-classic-content.grey .layout-classic-content-sider {
  box-shadow: unset;
  border-right: 1px solid #f1f1f1;
}
.layout-classic .ant4-layout-header.layout-classic-header.default {
  background: var(--ant4-primary-color);
}
.layout-classic .ant4-layout-header.layout-classic-header.default .ant4-menu-horizontal.ant4-menu-dark {
  background: transparent;
}
.layout-classic .ant4-layout-header.layout-classic-header.default .ant4-menu-horizontal.ant4-menu-dark .ant4-menu-item-selected {
  background-color: var(--ant4-primary-7);
}
.layout-classic .ant4-layout-header.layout-classic-header.default .ant4-menu-horizontal.ant4-menu-dark .ant4-menu-item-active {
  background-color: var(--ant4-primary-7);
}
.layout-classic .ant4-layout-header.layout-classic-header.default .primary-header-dropdown:hover {
  background-color: var(--ant4-primary-7);
}
.layout-classic .ant4-layout-header.layout-classic-header.default .primary-header-dropdown.ant4-dropdown-open {
  background-color: var(--ant4-primary-7);
}
.layout-classic .ant4-layout-header.layout-classic-header.default .primary-header-button {
  color: rgba(255, 255, 255, 0.65);
}
.layout-classic .ant4-layout-header.layout-classic-header.default .primary-header-button:hover {
  background-color: var(--ant4-primary-7);
  color: white;
}
.layout-classic .ant4-layout-header.layout-classic-header.default .ant4-avatar.login {
  background: rgba(255, 255, 255, 0.17);
  color: white;
}
.layout-classic .ant4-layout-header.layout-classic-header.dark .ant4-menu-horizontal.ant4-menu-dark {
  background: transparent;
}
.layout-classic .ant4-layout-header.layout-classic-header.dark .ant4-menu-horizontal.ant4-menu-dark .ant4-menu-item-selected {
  background-color: var(--ant4-primary-color);
}
.layout-classic .ant4-layout-header.layout-classic-header.dark .ant4-menu-horizontal.ant4-menu-dark .ant4-menu-item-active {
  background-color: var(--ant4-primary-color);
}
.layout-classic .ant4-layout-header.layout-classic-header.dark .primary-header-dropdown:hover {
  background-color: var(--ant4-primary-color);
}
.layout-classic .ant4-layout-header.layout-classic-header.dark .primary-header-dropdown.ant4-dropdown-open {
  background-color: var(--ant4-primary-color);
}
.layout-classic .ant4-layout-header.layout-classic-header.dark .primary-header-button {
  color: rgba(255, 255, 255, 0.65);
}
.layout-classic .ant4-layout-header.layout-classic-header.dark .primary-header-button:hover {
  background-color: var(--ant4-primary-color);
  color: white;
}
.layout-classic .ant4-layout-header.layout-classic-header.dark .ant4-avatar.login {
  background: rgba(255, 255, 255, 0.17);
  color: white;
}
.layout-classic .ant4-layout-header.layout-classic-header.light .ant4-menu-horizontal.ant4-menu-light {
  background: transparent;
  border-bottom: 0;
}
.layout-classic .ant4-layout-header.layout-classic-header.light .ant4-menu-horizontal.ant4-menu-light .ant4-menu-item-selected {
  background-color: var(--ant4-primary-1);
}
.layout-classic .ant4-layout-header.layout-classic-header.light .ant4-menu-horizontal.ant4-menu-light .ant4-menu-item-active {
  background-color: var(--ant4-primary-1);
}
.layout-classic .ant4-layout-header.layout-classic-header.light .ant4-menu-horizontal.ant4-menu-light .ant4-menu-item.ant4-menu-item-only-child {
  padding: 0 18px;
  margin: 0 2px;
}
.layout-classic .ant4-layout-header.layout-classic-header.light .ant4-menu-horizontal.ant4-menu-light .ant4-menu-submenu.ant4-menu-submenu-horizontal {
  padding: 0 18px;
  margin: 0 2px;
}
.layout-classic .ant4-layout-header.layout-classic-header.light .primary-header-dropdown:hover {
  color: var(--ant4-primary-color);
  background-color: var(--ant4-primary-1);
}
.layout-classic .ant4-layout-header.layout-classic-header.light .primary-header-dropdown.ant4-dropdown-open {
  color: var(--ant4-primary-color);
  background-color: var(--ant4-primary-1);
}
.layout-classic .ant4-layout-header.layout-classic-header.light .primary-header-button {
  color: rgba(0, 0, 0, 0.65);
}
.layout-classic .ant4-layout-header.layout-classic-header.light .primary-header-button.strong {
  color: black;
}
.layout-classic .ant4-layout-header.layout-classic-header.light .primary-header-button:hover {
  color: var(--ant4-primary-color);
  background-color: var(--ant4-primary-1);
}
.layout-classic .ant4-layout-header.layout-classic-header.light .ant4-avatar.login {
  background: var(--ant4-primary-color);
  color: white;
}
.layout-classic .layout-classic-content .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-submenu-selected {
  background-color: var(--ant4-primary-1);
}
.ant4-menu-dark.default.header-menu {
  background: var(--ant4-primary-color);
}
.ant4-menu-dark.default.header-menu .ant4-menu-vertical {
  background: transparent;
}
.ant4-menu-dark.default.header-menu .ant4-menu-submenu-open {
  background-color: var(--ant4-primary-7) !important;
}
.ant4-menu-dark.default.header-menu .ant4-menu-submenu-selected {
  color: white !important;
  background-color: var(--ant4-primary-7) !important;
}
.ant4-menu-dark.default.header-menu .ant4-menu-submenu-title a {
  color: unset;
}
.ant4-menu-dark.default.header-menu .ant4-menu-item a {
  color: unset;
}
.ant4-menu-dark.default.header-menu .ant4-menu-item a:hover {
  color: unset;
}
.ant4-menu-dark.default.header-menu.ant4-menu-submenu-popup .ant4-menu-item {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  height: 50px !important;
  line-height: 50px !important;
}
.ant4-menu-dark.default.header-menu.ant4-menu-submenu-popup .ant4-menu-item-selected {
  background-color: var(--ant4-primary-7) !important;
}
.ant4-menu-dark.default.header-menu.ant4-menu-submenu-popup .ant4-menu-item-active {
  background-color: var(--ant4-primary-7) !important;
}
.ant4-menu-dark.default.header-menu.header-menu-popup .ant4-menu-submenu-title,
.ant4-menu-dark.default.header-menu.header-menu-popup .ant4-menu-item {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  height: 50px !important;
  line-height: 50px !important;
}
.ant4-menu-dark.default.header-menu.header-menu-popup .ant4-menu-item-selected {
  background-color: var(--ant4-primary-7) !important;
}
.ant4-menu-dark.default.header-menu.header-menu-popup .ant4-menu-item-active {
  background-color: var(--ant4-primary-7) !important;
}
.ant4-menu-dark.dark.header-menu {
  background: #3d4146;
}
.ant4-menu-dark.dark.header-menu .ant4-menu-sub {
  background: transparent;
}
.ant4-menu-dark.dark.header-menu .ant4-menu-submenu-open {
  background-color: var(--ant4-primary-color) !important;
}
.ant4-menu-dark.dark.header-menu .ant4-menu-submenu-selected {
  color: white !important;
  background-color: var(--ant4-primary-color) !important;
}
.ant4-menu-dark.dark.header-menu .ant4-menu-submenu-title a {
  color: unset;
}
.ant4-menu-dark.dark.header-menu .ant4-menu-item a {
  color: unset;
}
.ant4-menu-dark.dark.header-menu .ant4-menu-item a:hover {
  color: unset;
}
.ant4-menu-dark.dark.header-menu.ant4-menu-submenu-popup .ant4-menu-item {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  height: 50px !important;
  line-height: 50px !important;
}
.ant4-menu-dark.dark.header-menu.ant4-menu-submenu-popup .ant4-menu-item-selected {
  background-color: var(--ant4-primary-color) !important;
}
.ant4-menu-dark.dark.header-menu.ant4-menu-submenu-popup .ant4-menu-item-active {
  background-color: var(--ant4-primary-color) !important;
}
.ant4-menu-dark.dark.header-menu.header-menu-popup .ant4-menu-submenu-title,
.ant4-menu-dark.dark.header-menu.header-menu-popup .ant4-menu-item {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  height: 50px !important;
  line-height: 50px !important;
}
.ant4-menu-dark.dark.header-menu.header-menu-popup .ant4-menu-item-selected {
  background-color: var(--ant4-primary-color) !important;
}
.ant4-menu-dark.dark.header-menu.header-menu-popup .ant4-menu-item-active {
  background-color: var(--ant4-primary-color) !important;
}
.ant4-menu-light.light.header-menu > li {
  top: 0;
}
.ant4-menu-light.light.header-menu .ant4-menu-submenu-open {
  background-color: var(--ant4-primary-1) !important;
}
.ant4-menu-light.light.header-menu .ant4-menu-submenu-selected {
  background-color: var(--ant4-primary-1) !important;
}
.ant4-menu-light.light.header-menu.ant4-menu-submenu-popup .ant4-menu-item {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  height: 50px !important;
  line-height: 50px !important;
}
.ant4-menu-light.light.header-menu.ant4-menu-submenu-popup .ant4-menu-item-selected {
  background-color: var(--ant4-primary-1) !important;
}
.ant4-menu-light.light.header-menu.ant4-menu-submenu-popup .ant4-menu-item-active {
  background-color: var(--ant4-primary-1) !important;
}
.ant4-menu-light.light.header-menu.header-menu-popup .ant4-menu-submenu-title,
.ant4-menu-light.light.header-menu.header-menu-popup .ant4-menu-item {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  height: 50px !important;
  line-height: 50px !important;
}
.ant4-menu-light.light.header-menu.header-menu-popup .ant4-menu-item-selected {
  background-color: var(--ant4-primary-1) !important;
}
.ant4-menu-light.light.header-menu.header-menu-popup .ant4-menu-item-active {
  background-color: var(--ant4-primary-1) !important;
}
.layout-classic-content-sider-drawer .addons .ant4-avatar.login {
  background: var(--ant4-primary-color);
  color: white;
}
.layout-classic-content-sider-drawer .ant4-drawer-body {
  padding: 0;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider {
  padding-top: 7px;
  display: flex;
  flex-direction: column;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .sider-title {
  font-size: 22px;
  padding: 15px 20px 10px 30px;
  margin-bottom: 10px;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider.ant4-layout-sider-collapsed .sider-title {
  visibility: hidden;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider.ant4-layout-sider-collapsed .sider-title:first-letter {
  visibility: visible !important;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .anticon {
  transition: font-size 0.3s;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-layout-sider-children {
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu.ant4-menu-inline {
  border-right-color: transparent;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-sub .ant4-menu-item > .ant4-menu-title-content {
  margin-left: 12px;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-sub .ant4-menu-submenu > .ant4-menu-submenu-title > .ant4-menu-title-content {
  margin-left: 12px;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-item {
  margin-bottom: 2px;
  margin-top: 2px;
  line-height: 44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-item .anticon {
  font-size: 26px;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-item:after {
  right: auto;
  left: 0;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-submenu-title {
  margin-bottom: 2px;
  margin-top: 2px;
  line-height: 44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-submenu-title .anticon {
  font-size: 26px;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-item-group {
  margin-bottom: 10px;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-item-group .ant4-menu-item-group-title {
  padding-left: 24px;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu.ant4-menu-inline .ant4-menu-sub.ant4-menu-inline {
  background: transparent;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu-inline-collapsed {
  width: calc(100% + 1px);
  border-right-color: transparent;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-item {
  margin-bottom: 2px;
  margin-top: 2px;
  line-height: 44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  padding-left: 0 !important;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-item .ant4-menu-item-icon {
  margin-top: 4px !important;
  font-size: 28px !important;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-item-group {
  margin-bottom: 10px;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-item-group .ant4-menu-item-group-title {
  padding-left: 24px;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-submenu-title {
  margin-bottom: 2px;
  margin-top: 2px;
  line-height: 44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  padding-left: 0 !important;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider .ant4-menu-inline-collapsed .ant4-menu-submenu-title .anticon {
  margin-top: 6px !important;
  font-size: 28px !important;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider.right .ant4-menu.ant4-menu-inline .ant4-menu-item:after {
  right: 0;
  left: auto;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider.right .ant4-menu.ant4-menu-inline .ant4-menu-sub .ant4-menu-item > .ant4-menu-title-content {
  margin-left: 0px;
}
.layout-classic-content-sider-drawer .ant4-drawer-body .layout-classic-content-sider.right .ant4-menu.ant4-menu-inline .ant4-menu-sub .ant4-menu-submenu > .ant4-menu-submenu-title > .ant4-menu-title-content {
  margin-left: 0px;
}
.layout-classic-content-sider-menu-popup .anticon {
  font-size: 26px !important;
}
.layout-classic-content-sider-menu-popup .ant4-menu-item {
  margin-bottom: 2px !important;
  margin-top: 2px !important;
  line-height: 44px !important;
  height: 44px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.layout-classic-content-sider-menu-popup .ant4-menu-submenu-title {
  margin-bottom: 2px !important;
  margin-top: 2px !important;
  line-height: 44px !important;
  height: 44px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.primary-header-dropdown-popup-menu .ant4-dropdown-menu-item {
  display: flex;
  align-items: center;
}
.primary-header-dropdown-popup-menu .ant4-dropdown-menu-item .anticon {
  font-size: 18px !important;
  color: #ababab;
}
.layout-classic-content-sider-menu-popup .ant4-menu-submenu-selected .ant4-menu-submenu-title {
  background-color: var(--ant4-primary-1);
}
@media screen and (max-width: 768px) {
  .layout-classic .ant4-layout-header.layout-classic-header > .header-content .app-title {
    flex: 1 1;
    padding: 0 10px;
  }
  .layout-classic .ant4-layout-header.layout-classic-header > .header-content .app-title .title {
    text-align: left;
    width: 0;
  }
  .layout-classic .ant4-layout-header.layout-classic-header > .header-content .menu-container {
    flex: 0 1;
  }
  .layout-classic .ant4-layout-header.layout-classic-header > .header-content .menu-container .ant4-menu {
    margin-left: 0 !important;
  }
  .layout-classic .ant4-layout-header.layout-classic-header > .header-content .addons {
    padding-right: 0 !important;
  }
}
