@import '@yulintu/freesia-design/css/variables.less';

.backstage-modal.app-gallery-modal {
    width    : 1188px !important;
    max-width: unset !important;

    .ant4-modal-content {

        .ant4-modal-header {
            padding-top: 22px;

            .ant4-modal-title {
                font-size: 22px;
                color    : #555;

                .action-bar {
                    height : 46px;
                    padding: 3px 0 3px 15px;
                }
            }
        }

        .ant4-modal-body {
            position     : relative;
            overflow     : hidden;
            padding-right: 0px;

            .background-svg {
                opacity : 0.3;
                position: absolute;
                left    : 0;
                right   : 0;
                bottom  : -50px;
            }
        }
    }

    .app-list-frame {
        height    : 530px;
        padding   : 0px 50px 20px 50px;
        overflow  : hidden;
        overflow-y: auto !important;

        .app-gallery-title {
            padding         : 0 0 10px 30px;
            // margin-bottom: 10px;
            margin-top      : 10px;
            // border-bottom: 2px solid #00000010;

            .title-text {
                font-size: 22px;
            }
        }

        .app-gallery-title:not(:first-child) {
            margin-top: 30px;
        }

        .list-grid--app-gallery {
            align-items  : flex-start;
            align-content: flex-start;

            .list-grid-v4-item--gallery-item {
                border-color: transparent;

                .app-gallery-item--gallery-item {
                    padding: 10px 15px;
                    width  : 480px;
                    height : 96px;

                    .logo {
                        width          : 64px;
                        height         : 64px;
                        display        : flex;
                        flex-direction : row;
                        justify-content: center;
                        align-items    : flex-start;

                        .anticon {
                            font-size: 56px;
                        }
                    }

                    .content {
                        margin-left: 15px;
                        overflow   : hidden;

                        .title {
                            font-size: 22px;
                            color    : black;

                            &:hover,
                            &:active {
                                color: inherit;
                            }
                        }

                        .description {
                            margin-bottom: 0;
                            color        : gray;
                            margin-top   : 0px;
                            font-size    : 13px;
                        }
                    }
                }

                &:hover,
                &:active {

                    .content {

                        .title {
                            color: inherit;

                        }
                    }
                }
            }
        }
    }
}

.fullscreen-dialog-wrap {
    .backstage-modal.app-gallery-modal {
        width: 100% !important;

        .ant4-modal-content {
            margin-left     : 0;
            margin-right    : 0;
            background-color: white !important;

            .ant4-modal-header {
                .ant4-modal-title {
                    font-size    : 26px;
                    margin-top   : 20px;
                    margin-bottom: 20px;
                    margin-left  : 35px;

                    .action-bar {
                        height : 46px;
                        padding: 3px 0;
                    }
                }
            }

            .ant4-modal-close {
                margin-right: 20px;
                margin-top  : 12px;
            }

            .ant4-modal-body {
                margin-bottom: 0;

                .app-list-frame {
                    height: 100%;
                }
            }
        }
    }
}


@media screen and (max-width: 1250px) {
    .backstage-modal.app-gallery-modal {
        width: 1024px !important;

        .app-list-frame {
            padding: 0px 0px 20px 0px;

            .list-grid--app-gallery {

                .list-grid-v4-item {
                    margin-top   : 5px;
                    margin-bottom: 5px;
                }

                .list-grid-v4-item--gallery-item {

                    .app-gallery-item--gallery-item {
                        width : 950px;
                        height: 96px;
                    }
                }
            }
        }
    }

    .fullscreen-dialog-wrap {
        .backstage-modal.app-gallery-modal {
            width: 100% !important;

            .app-list-frame {
                padding: 0px 30px 30px 30px;

                .list-grid--app-gallery {

                    .list-grid-v4-item {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .backstage-modal.app-gallery-modal {
        width: 700px !important;

        .app-list-frame {
            padding: 0px 0px 20px 0px;

            .list-grid--app-gallery {

                .list-grid-v4-item--gallery-item {

                    .app-gallery-item--gallery-item {
                        width : 620px;
                        height: 96px;
                    }
                }
            }
        }
    }

    .fullscreen-dialog-wrap {
        .backstage-modal.app-gallery-modal {
            width: 100% !important;

            .app-list-frame {
                padding: 0px 30px 30px 30px;

                .list-grid--app-gallery {

                    .list-grid-v4-item {
                        width: 100%;
                    }

                }
            }
        }
    }
}

@media screen and (max-height: 768px) {
    .backstage-modal.app-gallery-modal {
        // width: 1024px !important;

        .app-list-frame {
            height: 400px;
        }
    }

    .fullscreen-dialog-wrap {
        .backstage-modal.app-gallery-modal {
            .app-list-frame {
                height: 100%;
            }
        }
    }
}

@media screen and (max-height: 650px) {
    .backstage-modal.app-gallery-modal {
        // width: 1024px !important;

        .app-list-frame {
            height: 300px;
        }
    }

    .fullscreen-dialog-wrap {
        .backstage-modal.app-gallery-modal {
            .app-list-frame {
                height: 100%;
            }
        }
    }
}

@media screen and (max-height: 550px) {
    .backstage-modal.app-gallery-modal {
        // width: 1024px !important;

        .app-list-frame {
            height: 200px;
        }
    }

    .fullscreen-dialog-wrap {
        .backstage-modal.app-gallery-modal {
            .app-list-frame {
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .backstage-modal.app-gallery-modal {
        width  : 100% !important;
        height : 100%;
        top    : 0;
        margin : 0;
        padding: 0;

        .ant4-modal-content {
            height        : 100%;
            border-radius : 0;
            display       : flex;
            flex-direction: column;

            .ant4-modal-body {
                height : 0;
                flex   : 1 1;
                padding: 10px 0 10px 10px;
            }
        }

        .app-list-frame {
            padding: 0px 0px 20px 0px;
            height : 100%;

            .list-grid--app-gallery {
                flex-direction: column;
                flex-wrap     : nowrap;
                width         : 100%;
                padding-right : 30px;

                .list-grid-v4-item {
                    width        : 100%;
                    margin-top   : 5px;
                    margin-bottom: 5px;
                }

                .list-grid-v4-item--gallery-item {
                    width: 100%;

                    .app-gallery-item--gallery-item {
                        width : 100%;
                        height: 96px;

                        .content {

                            .title {
                                font-size: 18px;
                            }

                            .description {
                                margin-bottom: 0.5em !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .fullscreen-dialog-wrap {
        .backstage-modal.app-gallery-modal {

            .ant4-modal-content {

                .ant4-modal-header {
                    .ant4-modal-title {
                        font-size    : 26px;
                        margin-top   : 10px;
                        margin-bottom: 10px;
                        margin-left  : 15px;
                    }
                }

                .ant4-modal-close {
                    margin-top  : 2px;
                    margin-right: 18px;
                }

                .app-list-frame {
                    padding: 0px 0px 20px 0px;
                }

            }
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;