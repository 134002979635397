.newpropertygrid-container {

    position: relative;

    .propertygrid {


        .propertygrid-sheet {

            background      : white;
            border          : 1px solid #e2e2e2;
            padding         : 12px 15px 15px 15px;
            // border-radius: 5px;

            margin-right : 10px;
            margin-left  : 10px;
            margin-bottom: 10px;
            box-shadow   : 0 0 3px #efefef;

            .title {
                display      : flex;
                margin       : 0 !important;
                font-size    : 15px !important;
                font-weight  : bold;
                margin-bottom: 15px !important;

                >.title-label {
                    // padding: 0 5px 3px 5px;
                    // border-bottom: 3px solid #2952c2;
                    // // border-radius: 4px;
                    // width        : auto;
                }
            }

            .property-item-dirty-name {
                >span {
                    text-decoration: line-through;
                }
            }

            .ant4-pro-checkcard {

                .ant4-pro-checkcard-avatar {
                    padding-right: 15px;
                }

                .title-card {
                    font-size  : 15px;
                    font-weight: bold;
                }
            }
        }
    }

    &.orient-v {

        .propertygrid {


            .propertygrid-sheet {

                .title {
                    font-size  : 18px !important;
                    font-weight: normal;
                    color      : black;
                    margin-left: 0px !important;
                }
            }
        }

        .ant-form-item {
            .ant-form-item-label {
                line-height : unset;
                padding-left: 1px;

                .description {
                    font-size: 12px;
                }
            }
        }

        .ant-form-item:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}

.newpropertygrid-container.form {
    .propertygrid {
        .propertygrid-sheet {
            border    : 0;
            box-shadow: unset;

            .title {
                font-size    : 22px !important;
                font-weight  : 400;
                color        : #000;
                margin-left  : 0 !important;
                margin-bottom: 25px !important;
            }

            .body {
                margin-left: 2px;
            }
        }
    }

    .ant4-form-item {
        margin-bottom: 5px !important;

        .ant4-form-item-label {
            margin-right: 5px;

            .name {
                color: black;
            }
        }

        .ant4-form-item-control {
            .ant4-form-item-explain {
                position   : absolute;
                left       : calc(100% + 8px);
                top        : 5px;
                white-space: nowrap;
            }
        }
    }

    &.orient-v {
        .ant4-form-item {
            margin-bottom: 15px !important;

            .ant4-form-item-label {
                padding-bottom: 4px;
            }
        }
    }
}

.newpropertygrid-container.kv {

    .propertygrid {

        .propertygrid-sheet {
            border       : 0;
            box-shadow   : unset;
            border-radius: 8px;
            padding      : 20px;

            .title {
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }

    .ant4-form-item {

        .ant4-form-item-label {
            .anticon {
                font-size: 22px;
            }

            .name {
                margin-left: 5px;
            }
        }
    }
}

.newpropertygrid-container.kv.compact {

    .ant4-form-item {

        margin-bottom: 8px;
    }
}
@ant-prefix: ant4;@root-entry-name: variable;