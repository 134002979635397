.fullscreen-dialog-wrap {
  background-color: #f0f2f5;
}
.fullscreen-dialog-wrap .ant4-modal {
  height: 100%;
  padding-bottom: 0;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content {
  box-shadow: unset;
  height: 100%;
  background-color: #f0f2f5 !important;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-close {
  top: 23px;
  margin-right: 10px;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-close .ant4-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.04);
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-close .ant4-modal-close-x .anticon-close {
  font-size: 24px;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-close:hover .ant4-modal-close-x {
  background-color: rgba(0, 0, 0, 0.07);
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-close:active .ant4-modal-close-x {
  background-color: rgba(0, 0, 0, 0.1);
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-header > .ant4-modal-title {
  margin-right: 70px;
  border-right: 1px solid #eaeaea;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-header > .ant4-modal-title > .fullscreen-modal-title {
  margin: 10px 30px 10px 0;
  font-size: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-header > .ant4-modal-title > .fullscreen-modal-title > .anticon {
  font-size: 50px;
  margin-right: 15px;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-header > .ant4-modal-title > .fullscreen-modal-title > .title {
  font-size: 22px;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-header > .ant4-modal-title > .fullscreen-modal-title > .title-second {
  font-size: 16px;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-header > .ant4-modal-title > .fullscreen-modal-title > button {
  height: 37px;
  min-width: 80px;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-footer {
  padding: 0px 16px 20px;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-body {
  flex: 1 1;
  padding: 0;
  margin-bottom: 24px;
  height: 0;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-body .ant4-tabs-top.gradient-ink-bar {
  overflow: unset;
}
.fullscreen-dialog-wrap .ant4-modal .ant4-modal-content .ant4-modal-body .ant4-tabs-top.gradient-ink-bar .ant4-tabs-content-top {
  overflow: unset;
}
