.tasks-observer-drawer .upload-panel .upload-item .status-text,
.tasks-observer-popup .upload-panel .upload-item .status-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tasks-observer-drawer .upload-panel .upload-item .status-text .info,
.tasks-observer-popup .upload-panel .upload-item .status-text .info {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 0;
  margin-left: 10px;
}
.tasks-observer-drawer .upload-panel .upload-item .status-text .info .name-row,
.tasks-observer-popup .upload-panel .upload-item .status-text .info .name-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tasks-observer-drawer .upload-panel .upload-item .status-text .info .name-row .name,
.tasks-observer-popup .upload-panel .upload-item .status-text .info .name-row .name {
  width: 0;
  flex: 1 1;
}
.tasks-observer-drawer .upload-panel .upload-item .status-text .info .description,
.tasks-observer-popup .upload-panel .upload-item .status-text .info .description {
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 3px;
}
