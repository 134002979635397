@import '@yulintu/freesia-design/css/variables.less';

.freedesign.theme-settings {

    .ant4-drawer-close {
        display: none;
    }

    .ant4-drawer-body {
        padding: 24px 26px;
    }

    .settings-group {
        padding: 0px 0 20px;

        &:not(:first-child) {
            margin-top: 20px;
        }

        display: flex;
        flex-direction: column;

        &.border {
            border: 0;
            border-bottom: 1px solid #f1f1f1;
        }

        .title {
            font-size: 15px;
            color: black;
            margin-bottom: 15px;
        }

        .settings-content {


            .style-item {
                position: relative;
                width: 44px;
                height: 36px;
                margin-right: 16px;
                border-radius: 4px;
                box-shadow: 0 1px 2.5px 0 #0000002e;
                cursor: pointer;

                display: flex;
                flex-direction: column;

                .header {
                    height: 9px;
                }

                .content {
                    flex: 1 1;
                    background-color: #f0f2f5;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color:~'var(--@{ant-prefix}-primary-color)';

                    .anticon {
                        font-size: 16px;
                    }
                }

                &.light {
                    .header {
                        background-color: white;
                    }
                }

                &.dark {
                    .header {
                        background-color: #3d4146;
                    }
                }

                &.default {
                    .header {
                        background-color:~'var(--@{ant-prefix}-primary-color)';
                    }
                }
            }

            .color-item {
                width: 20px;
                height: 20px;
                margin-right: 8px;
                color: #fff;
                font-weight: 700;
                text-align: center;
                border-radius: 2px;
                cursor: pointer;
                box-shadow: 0 1px 2.5px 0 #0000002e;

                display: flex;
                flex-shrink: 0;
                justify-content: center;
                align-items: center;
            }

            .settings-item {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                .name {
                    flex: 1 1;
                }
            }

            .color-picker-frame {
                position: relative;

                .mask {
                    background-color: #ffffffc0;
                }

                .sketch-picker {
                    padding: 0 !important;
                    width: 100% !important;
                    box-shadow: unset !important;
                    margin-top: 15px !important;
                }
            }

        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;