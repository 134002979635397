.echo-text {
    font-size: 128px;
    color: #bfbfbf;
}



@media screen and (max-width: 600px) {
    .echo-text {
        font-size: 64px;
    }
}
@ant-prefix: ant4;@root-entry-name: variable;