.layout-micro-app-frame {
    width : 100%;
    height: 100%;

    .layout-micro-app {
        width : 100%;
        height: 100%;

        >div {
            height: 100%;
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;