.freesia-design {
    @import "./overwrites.less";
    background-color: transparent;
}

html {
    @success-color                      : #64A319 !important;
    --@{ant-prefix}-success-color       : @success-color;
    --@{ant-prefix}-success-color-hover : lighten(@success-color, 10%);
    --@{ant-prefix}-success-color-active: darken(@success-color, 10%);

    @error-color                      : #c5474a !important;
    --@{ant-prefix}-error-color       : @error-color;
    --@{ant-prefix}-error-color-hover : lighten(@error-color, 10%);
    --@{ant-prefix}-error-color-active: darken(@error-color, 10%);

    @warning-color                      : #f58800 !important;
    --@{ant-prefix}-warning-color       : @warning-color;
    --@{ant-prefix}-warning-color-hover : lighten(@warning-color, 10%);
    --@{ant-prefix}-warning-color-active: darken(@warning-color, 10%);
}
@ant-prefix: ant4;@root-entry-name: variable;