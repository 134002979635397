@import '@yulintu/freesia-design/css/variables.less';

.resizable-datatable {



    .ant-table {
        background-color: #fff;
    }

    .ant4-table-content {
        // background-color: whitesmoke;

        >table {
            border-top: 1px solid rgb(239 239 239) !important;
        }
    }


    table {

        width    : unset !important;
        min-width: unset !important;


        // border-top: 0px !important;

        .ant-table-selection-column {
            display: none;
        }



        th .td-content {
            word-break    : keep-all;
            text-overflow : ellipsis;
            overflow      : hidden;
            vertical-align: middle;
            white-space   : nowrap;
        }

        th .dropdown {
            position: absolute;
            right   : 5px;
            top     : 5px;

            a {
                color: #888888;
            }
        }

        td>.td-content {
            height        : 100%;
            word-break    : keep-all;
            white-space   : nowrap;
            text-overflow : ellipsis;
            display       : inline-block;
            overflow      : hidden;
            vertical-align: middle;
            padding       : 6px 5px 5px 5px;
            border-width  : 2px;
            border-style  : solid;

            .td-content {
                width: 100%;
            }
        }

        td>.td-content.td-default {
            border-color: transparent;
        }

        td>.td-content.ant-pagination-item-active {
            background  : unset;
            border-color:~'var(--@{ant-prefix}-primary-color)';
        }

        tr {
            background-color: white;
        }

        td {
            font-size : 9pt;
            padding   : 0px !important;
            height    : 36px;
            transition: none;
        }

        th {
            transition         : none;
            padding            : 0px 0 !important;
            // background   : white !important;
            // border-bottom   : 1px solid #d9d9d9 !important;
            border-top         : 0px !important;
            box-shadow         : 0 1px 4px #00000012;
            z-index            : 1;

            >div {
                padding: 8px 7px;

            }

            .react-resizable-handle-se {
                position: absolute;
                width   : 10px;
                height  : 100%;
                bottom  : 0;
                right   : -5px;
                cursor  : col-resize;
                z-index : 9999;
            }

            .name {
                white-space  : nowrap;
                word-wrap    : none;
                word-break   : keep-all;
                text-overflow: ellipsis;
            }

            .type {
                font-size    : 13px;
                font-weight  : normal;
                color        : #777;
                white-space  : nowrap;
                word-wrap    : none;
                word-break   : keep-all;
                text-overflow: ellipsis;
            }
        }

        .th-default {
            background: white !important;
        }


    }

    .ant4-table-empty {

        .ant4-table-placeholder {
            display: none;
        }
    }


    .ant-spin-nested-loading {
        flex          : 1 1;
        display       : flex;
        flex-direction: column;
        height        : 0;

        .ant-spin-container {
            flex          : 1 1;
            display       : flex;
            flex-direction: column;
            height        : 0;

            .ant-table-content {
                height: 100%;

                .ant-table-scroll {
                    height: 100%;

                    .ant-table-body {
                        height: 100%;
                    }
                }
            }

            .ant-table-empty {
                flex          : 1 1;
                display       : flex;
                flex-direction: column;

                .ant-table-content {
                    flex: 1 1;

                    display       : flex;
                    flex-direction: column;

                    .ant-table-scroll {
                        display: unset !important;
                    }

                    .ant-table-placeholder {
                        display      : none;
                        flex         : 1 1;
                        border-bottom: none;
                        border-left  : none;

                        display       : flex;
                        flex-direction: row;
                        align-items   : center;


                        .ant-empty {
                            width: 100%;
                        }
                    }


                }
            }
        }
    }

    &.has-none-tip {
        .ant-table-placeholder {
            display     : flex !important;
            border-right: none !important;
        }
    }
}

.resizable-datatable-popover-paragraph {
    margin-bottom: 0 !important;

    .ant-typography-copy {
        margin-left: 0;
    }

    .anticon {
        font-size: 16px;
    }
}
@ant-prefix: ant4;@root-entry-name: variable;