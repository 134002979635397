.freedesign.theme-settings .ant4-drawer-close {
  display: none;
}
.freedesign.theme-settings .ant4-drawer-body {
  padding: 24px 26px;
}
.freedesign.theme-settings .settings-group {
  padding: 0px 0 20px;
  display: flex;
  flex-direction: column;
}
.freedesign.theme-settings .settings-group:not(:first-child) {
  margin-top: 20px;
}
.freedesign.theme-settings .settings-group.border {
  border: 0;
  border-bottom: 1px solid #f1f1f1;
}
.freedesign.theme-settings .settings-group .title {
  font-size: 15px;
  color: black;
  margin-bottom: 15px;
}
.freedesign.theme-settings .settings-group .settings-content .style-item {
  position: relative;
  width: 44px;
  height: 36px;
  margin-right: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2.5px 0 #0000002e;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.freedesign.theme-settings .settings-group .settings-content .style-item .header {
  height: 9px;
}
.freedesign.theme-settings .settings-group .settings-content .style-item .content {
  flex: 1 1;
  background-color: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ant4-primary-color);
}
.freedesign.theme-settings .settings-group .settings-content .style-item .content .anticon {
  font-size: 16px;
}
.freedesign.theme-settings .settings-group .settings-content .style-item.light .header {
  background-color: white;
}
.freedesign.theme-settings .settings-group .settings-content .style-item.dark .header {
  background-color: #3d4146;
}
.freedesign.theme-settings .settings-group .settings-content .style-item.default .header {
  background-color: var(--ant4-primary-color);
}
.freedesign.theme-settings .settings-group .settings-content .color-item {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 1px 2.5px 0 #0000002e;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.freedesign.theme-settings .settings-group .settings-content .settings-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.freedesign.theme-settings .settings-group .settings-content .settings-item .name {
  flex: 1 1;
}
.freedesign.theme-settings .settings-group .settings-content .color-picker-frame {
  position: relative;
}
.freedesign.theme-settings .settings-group .settings-content .color-picker-frame .mask {
  background-color: #ffffffc0;
}
.freedesign.theme-settings .settings-group .settings-content .color-picker-frame .sketch-picker {
  padding: 0 !important;
  width: 100% !important;
  box-shadow: unset !important;
  margin-top: 15px !important;
}
