.layout-classic-content-sider-drawer .addons .user-menu,
.layout-classic-header .addons .user-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 17px !important;
}
.layout-classic-content-sider-drawer .addons .user-menu > .user-avatar,
.layout-classic-header .addons .user-menu > .user-avatar {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.layout-classic-content-sider-drawer .addons .user-menu > .user-avatar > .ant4-avatar > .anticon,
.layout-classic-header .addons .user-menu > .user-avatar > .ant4-avatar > .anticon {
  font-size: 24px;
  transform: translateY(-1px);
}
.layout-classic-content-sider-drawer .addons .user-menu > .user-avatar > .ant4-avatar > .ant4-avatar-string,
.layout-classic-header .addons .user-menu > .user-avatar > .ant4-avatar > .ant4-avatar-string {
  transform: translateY(-1px) translateX(-50%) !important;
  font-size: 24px;
}
.layout-classic-content-sider-drawer .addons .user-menu > .user-avatar > .user-portrait,
.layout-classic-header .addons .user-menu > .user-avatar > .user-portrait {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid #ffffff80;
  border-image-width: 0;
  border-radius: 100%;
}
.layout-classic-content-sider-drawer .addons .user-menu > .name,
.layout-classic-header .addons .user-menu > .name {
  margin-left: 8px;
  font-size: 14px;
}
.layout-classic-content-sider-drawer .addons .user-menu > .user-avatar > .user-portrait,
.layout-classic-header.light .addons .user-menu > .user-avatar > .user-portrait {
  border: 0;
}
.layout-classic-content-sider-drawer .addons {
  margin-top: 10px;
  padding: 15px 22px 10px 22px;
  border-top: 1px solid #f1f1f1;
}
.layout-classic-content-sider-drawer .addons .user-menu {
  padding: 0 17px 0 0 !important;
}
