.resizable-datatable .ant-table {
  background-color: #fff;
}
.resizable-datatable .ant4-table-content > table {
  border-top: 1px solid rgb(239 239 239) !important;
}
.resizable-datatable table {
  width: unset !important;
  min-width: unset !important;
}
.resizable-datatable table .ant-table-selection-column {
  display: none;
}
.resizable-datatable table th .td-content {
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
}
.resizable-datatable table th .dropdown {
  position: absolute;
  right: 5px;
  top: 5px;
}
.resizable-datatable table th .dropdown a {
  color: #888888;
}
.resizable-datatable table td > .td-content {
  height: 100%;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  padding: 6px 5px 5px 5px;
  border-width: 2px;
  border-style: solid;
}
.resizable-datatable table td > .td-content .td-content {
  width: 100%;
}
.resizable-datatable table td > .td-content.td-default {
  border-color: transparent;
}
.resizable-datatable table td > .td-content.ant-pagination-item-active {
  background: unset;
  border-color: var(--ant4-primary-color);
}
.resizable-datatable table tr {
  background-color: white;
}
.resizable-datatable table td {
  font-size: 9pt;
  padding: 0px !important;
  height: 36px;
  transition: none;
}
.resizable-datatable table th {
  transition: none;
  padding: 0px 0 !important;
  border-top: 0px !important;
  box-shadow: 0 1px 4px #00000012;
  z-index: 1;
}
.resizable-datatable table th > div {
  padding: 8px 7px;
}
.resizable-datatable table th .react-resizable-handle-se {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 9999;
}
.resizable-datatable table th .name {
  white-space: nowrap;
  word-wrap: none;
  word-break: keep-all;
  text-overflow: ellipsis;
}
.resizable-datatable table th .type {
  font-size: 13px;
  font-weight: normal;
  color: #777;
  white-space: nowrap;
  word-wrap: none;
  word-break: keep-all;
  text-overflow: ellipsis;
}
.resizable-datatable table .th-default {
  background: white !important;
}
.resizable-datatable .ant4-table-empty .ant4-table-placeholder {
  display: none;
}
.resizable-datatable .ant-spin-nested-loading {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 0;
}
.resizable-datatable .ant-spin-nested-loading .ant-spin-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 0;
}
.resizable-datatable .ant-spin-nested-loading .ant-spin-container .ant-table-content {
  height: 100%;
}
.resizable-datatable .ant-spin-nested-loading .ant-spin-container .ant-table-content .ant-table-scroll {
  height: 100%;
}
.resizable-datatable .ant-spin-nested-loading .ant-spin-container .ant-table-content .ant-table-scroll .ant-table-body {
  height: 100%;
}
.resizable-datatable .ant-spin-nested-loading .ant-spin-container .ant-table-empty {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.resizable-datatable .ant-spin-nested-loading .ant-spin-container .ant-table-empty .ant-table-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.resizable-datatable .ant-spin-nested-loading .ant-spin-container .ant-table-empty .ant-table-content .ant-table-scroll {
  display: unset !important;
}
.resizable-datatable .ant-spin-nested-loading .ant-spin-container .ant-table-empty .ant-table-content .ant-table-placeholder {
  display: none;
  flex: 1 1;
  border-bottom: none;
  border-left: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.resizable-datatable .ant-spin-nested-loading .ant-spin-container .ant-table-empty .ant-table-content .ant-table-placeholder .ant-empty {
  width: 100%;
}
.resizable-datatable.has-none-tip .ant-table-placeholder {
  display: flex !important;
  border-right: none !important;
}
.resizable-datatable-popover-paragraph {
  margin-bottom: 0 !important;
}
.resizable-datatable-popover-paragraph .ant-typography-copy {
  margin-left: 0;
}
.resizable-datatable-popover-paragraph .anticon {
  font-size: 16px;
}
