.layout-classic-content-sider-drawer,
.layout-classic-header {
    .addons {
        .user-menu {
            display       : flex;
            flex-direction: row;
            align-items   : center;
            padding       : 0 17px !important;

            >.user-avatar {
                position       : relative;
                width          : 40px;
                height         : 40px;
                display        : flex;
                justify-content: center;
                align-items    : center;

                >.ant4-avatar {
                    >.anticon {
                        // color    : rgba(255, 255, 255, 0.65);
                        font-size: 24px;
                        transform: translateY(-1px);
                    }

                    >.ant4-avatar-string {
                        transform: translateY(-1px) translateX(-50%) !important;
                        font-size: 24px;
                    }
                }

                >.user-portrait {
                    position          : absolute;
                    width             : 100%;
                    height            : 100%;
                    border            : 2px solid #ffffff80;
                    border-image-width: 0;
                    border-radius     : 100%;
                }

            }

            >.name {
                // color      : rgba(255, 255, 255, 0.65);
                // margin-top : 1px;
                margin-left: 8px;
                font-size  : 14px;
            }
        }
    }

}

.layout-classic-content-sider-drawer,
.layout-classic-header.light {
    .addons {
        .user-menu {
            >.user-avatar {
                >.user-portrait {
                    border: 0;
                }
            }
        }
    }
}

.layout-classic-content-sider-drawer {
    .addons {
        margin-top: 10px;
        padding   : 15px 22px 10px 22px;
        border-top: 1px solid #f1f1f1;

        .user-menu {
            padding: 0 17px 0 0 !important;
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;