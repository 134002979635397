.freesia-design.sample-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.freesia-design.sample-page > .tools {
  padding: 15px 10px 15px 24px;
  border-bottom: 1px solid #e8e8e8;
  z-index: 1;
}
.freesia-design.sample-page > .tools .ant4-radio-button-wrapper {
  padding: 0 0px;
  width: 32px;
}
.freesia-design.sample-page > .tools .ant4-radio-button-wrapper > span:last-child {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.freesia-design.sample-page > .tools .ant4-radio-button-wrapper > span:last-child .anticon {
  font-size: 18px;
}
.freesia-design.sample-page > .content {
  overflow-y: auto;
}
.freesia-design.sample-page > .content .selector-files {
  height: 40px;
}
.freesia-design.sample-page > .content .selector-files .ant4-select-selector {
  height: 40px;
}
.freesia-design.sample-page > .content .ant4-typography pre {
  margin: 16px 0;
  padding: 12px 20px;
  border: 0px;
  border-radius: 0;
  background: rgba(197, 197, 197, 0.1);
}
@media screen and (max-width: 500px) {
  .freesia-design.sample-page > .tools {
    padding: 15px 10px 15px 54px;
  }
}
