@import '@yulintu/freesia-design/css/variables.less';

.tasks-observer-drawer,
.tasks-observer-popup {
    .tasks-panel {

        .action-bar {
            margin     : 0 10px 5px 10px;
            padding    : 10px 15px 0px 15px;
            flex-shrink: 0;
        }

        .task-item {
            .status-text {
                display       : flex;
                flex-direction: row;
                align-items   : flex-start;

                >.anticon {
                    margin-top: 5px;
                }

                .info {
                    display       : flex;
                    flex-direction: column;
                    flex          : 1 1;
                    width         : 0;
                    margin-left   : 10px;

                    .name-row {
                        display       : flex;
                        flex-direction: row;
                        align-items   : center;

                        .name {
                            width    : 0;
                            flex     : 1 1;
                            font-size: 15px;
                        }

                        .ant4-tag {
                            margin-right: 0;
                        }

                        .mgl5 {
                            margin-left: 5px !important;
                        }

                        .stop {
                            padding        : 2px 3px 0px 3px;
                            height         : 22px;
                            margin-left    : 3px;
                            display        : flex;
                            justify-content: center;
                            align-items    : center;
                        }
                    }

                    .description {
                        opacity      : .7;
                        font-size    : 13px;
                        margin-bottom: 3px;
                    }
                }

                .time {
                    display       : flex;
                    flex-direction: row;
                    align-items   : center;

                    .key {
                        opacity  : .7;
                        font-size: 13px;
                    }

                    .value {
                        margin-left: 3px;
                        opacity    : .7;
                        font-size  : 13px;
                    }

                    .duration {
                        flex: 1 1;
                    }
                }
            }

            .ant4-progress.has-warn {
                .anticon-check-circle {
                    color:~'var(--@{ant-prefix}-warning-color)';
                }
            }
        }
    }
}
@ant-prefix: ant4;@root-entry-name: variable;